export const FETCH_ALL_ROLES_REQUEST = 'FETCH_ALL_ROLES_REQUEST';
export const FETCH_ALL_ROLES_SUCCEEDED = 'FETCH_ALL_ROLES_SUCCEEDED';
export const FETCH_ALL_ROLES_FAILED = 'FETCH_ALL_ROLES_FAILED';

export const SET_SELECTED_ROLES = 'SET_SELECTED_ROLES';
export const RESET_ROLES_CREATION = 'RESET_ROLES_CREATION';

export const CREATE_ROLES_REQUEST = 'CREATE_ROLES_REQUEST';
export const CREATE_ROLES_SUCCEEDED = 'CREATE_ROLES_SUCCEEDED';
export const CREATE_ROLES_FAILED = 'CREATE_ROLES_FAILED';

export const DELETE_ROLES_REQUEST = 'DELETE_ROLES_REQUEST';
export const DELETE_ROLES_SUCCEEDED = 'DELETE_ROLES_SUCCEEDED';
export const DELETE_ROLES_FAILED = 'DELETE_ROLES_FAILED';

export const ADD_CHILDS_REQUEST = 'ADD_CHILDS_REQUEST';
export const ADD_CHILDS_SUCCEEDED = 'ADD_CHILDS_SUCCEEDED';
export const ADD_CHILDS_FAILED = 'ADD_CHILDS_FAILED';
export const ADD_CHILDS_RESET = 'ADD_CHILDS_RESET';

export const SET_ROLE_DEFAULT_REQUEST = 'SET_ROLE_DEFAULT_REQUEST';
export const SET_ROLE_DEFAULT_SUCCEEDED = 'SET_ROLE_DEFAULT_SUCCEEDED';
export const SET_ROLE_DEFAULT_FAILED = 'SET_ROLE_DEFAULT_FAILED';
export const SET_ROLE_DEFAULT_RESET = 'SET_ROLE_DEFAULT_RESET';

import React, { useState } from 'react';
import { Error, FileCopy } from '@material-ui/icons';
import copyTextToClipboard from '../../utils/copyToClipboard';
import styles from './CopyPopUp.module.css';
import AccountLoading from '../library/Loading';
import Button from '../library/Button/Button';
import ErrorAnimation from '../library/ErrorAnimation/ErrorAnimation';

export default function CopyPopUp({
  status, close, error, loading, success, value,
}) {
  const [isCopy, setIsCopy] = useState(false);
  const [isCopySupported, setIsCopySupported] = useState(true);

  const handleUrlCopy = () => {
    const onSuccess = () => {
      setIsCopy(true);
      window.setTimeout(() => setIsCopy(false), 1000);
    };
    const onError = () => {
      setIsCopy(false);
      setIsCopySupported(false);
    };
    copyTextToClipboard(value, onSuccess, onError);
  };
  return (
    <div className={styles.background}>
      <section className={styles.modal}>
        {
          (status === 'LOADING')
          && (
            <>
              <h3 className={styles.title}>
                {loading}
              </h3>
              <AccountLoading />
              <Button
                variant="outline"
                label="Cancel"
                onButtonClick={close}
                className={styles.button}
              />
            </>
          )
        }
        {
          (status === 'ERROR')
          && (
            <>
              <h3 className={styles.title}>
                Request failed
              </h3>
              <p className={styles.description}>
                { error }
              </p>
              <ErrorAnimation />
              <Button
                label="OK"
                onButtonClick={close}
                className={styles.button}
              />
            </>
          )
        }
        {
          (status === 'SUCCESS')
          && (
            <>
              <h3 className={styles.title}>
                Request succeeded
              </h3>
              <p className={styles.description}>
                { success }
              </p>
              {
                (isCopySupported)
                  ? (
                    <Button
                      type="custom"
                      label={(!isCopy) ? 'Copy' : 'Copied !'}
                      variant="ghost"
                      icon={<FileCopy className={styles.icon} />}
                      className={styles.custom}
                      disabled={isCopy}
                      onButtonClick={handleUrlCopy}
                    />
                  )
                  : (
                    <p className={styles.error}>
                      <span className={styles.lineHeight}>
                        <Error className={styles.icon} />
                      </span>
                      <span>
                        Direct copy to clipboard is not supported yet by your browser.
                        Please copy directly the following link by hand.
                      </span>
                    </p>
                  )
              }
              <p className={styles.token}>
                { value }
              </p>
              <Button
                label="Close"
                className={styles.button}
                onButtonClick={close}
              />
            </>
          )
        }
      </section>
    </div>
  );
}

import React from 'react';
import { Done, Remove } from '@material-ui/icons';

import styles from './Checkbox.module.css';

export default function Checkbox({
  type = 'ITEM', checked, selected, onChange,
}) {
  if (type === 'ITEM') {
    return (
      <button
        type="button"
        className={`${styles.checkbox} ${(checked) ? styles.checked : ''}`}
        onClick={() => onChange(!checked)}
      >
        { checked && <Done />}
      </button>
    );
  }
  if (type === 'GROUP') {
    return (
      <button
        type="button"
        className={`${styles.checkbox} ${(checked) ? styles.checked : ''}`}
        onClick={() => onChange(!checked)}
      >
        { selected && <Remove />}
      </button>
    );
  }
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import SocialAuthentication from '../../components/SocialAuthentification';
import jwt from 'jsonwebtoken';
import validateMail from '../../utils/validateMail';
import redirect from '../../utils/redirect';

import styles from './LogIn.module.css';
import LogInForm from './LogInForm';
import { getUserToken, logIn } from '../../services/network/authentication';
import DoubleAuth from './DoubleAuth';
import ForceReset from '../ForceReset';
import Loading from '../../components/library/Loading';
import { axiosDefaultClient as axios } from '../../services/network/axiosClient';
import { BRAND } from '../../global';
import { getStorage, setStorage } from '../../utils/saveLocal';

export default function LogIn() {
  const [status, setStatus] = useState('LOADING');
  const [message, setMessage] = useState(null);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isCompany, setIsCompany] = useState(false);
  const [companyName, setCompanyName] = useState(BRAND);

  const { t } = useTranslation();

  useEffect(() => {
    const checkIfConnected = async () => {
      setStatus('LOADING');
      try {
        const token = getStorage('auth_token');
        const params = new URLSearchParams(window.location.search);
        const refreshToken = params.get('refresh_token') || getStorage('auth_refresh', false);
        if (!refreshToken) {
          setStatus('DEFAULT');
          return;
        }
        const { data } = await axios.get('/auth/cert');
        const { cert } = data.data;
        jwt.verify(token, cert, async (err) => {
          if (!err) {
            redirect(
              {
                data: {
                  token,
                  refresh_token: refreshToken,
                },
              },
              0,
            );
          } else if (refreshToken) {
            const { data: dataRefresh } = await axios.post('/auth/refresh_token', { refresh_token: refreshToken });
            if (dataRefresh.status === 'OK') {
              setStorage('auth_token', dataRefresh.data.token);
              setStorage('auth_refresh', dataRefresh.data.refresh_token, false);
              await checkIfConnected();
            } else {
              setStatus('DEFAULT');
            }
          }
        });
      } catch (err) {
        console.error('[ERROR]: ', err);
        setStatus('DEFAULT');
      }
    };
    const params = new URLSearchParams(window.location.search);
    const asUser = params.get('asUser');
    const hasRedirect = params.get('redirect');
    const email = params.get('email');
    if (hasRedirect) {
      const subdomain = new RegExp('[.]lendeers[.]com');
      if (hasRedirect.search(subdomain) !== -1) {
        setIsCompany(true);
        setCompanyName(/:\/\/([^.]+)/.exec(hasRedirect)[1].toUpperCase());
      }
    }
    if (email) {
      setValues({ email, password: '' });
    }
    if (!asUser) {
      checkIfConnected();
    } else {
      setStatus('DEFAULT');
    }
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!values.email) {
      newErrors.email = t('logIn.errors.mailEmpty');
    }
    if (values.email && !validateMail(values.email)) {
      newErrors.email = t('logIn.errors.mailError');
    }
    if (!values.password) {
      newErrors.password = t('logIn.errors.passwordEmpty');
    }
    if (values.emailAsUser && !validateMail(values.emailAsUser)) {
      newErrors.emailAsUser = t('logIn.errors.emailAsUserInvalid');
    }
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validate();
    setErrors(newErrors);
    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
    } else {
      setStatus('LOADING');
      try {
        const { data } = await logIn({ ...values, password: values.password.trim() });
        if (data.status !== 'KO') {
          setStatus('SUCCESS');
          if (data.data?.refresh_token) {
            setStorage('auth_refresh', data.data?.refresh_token, false);
          }
          if (data.data?.double_authentication) {
            setStatus('DOUBLE_AUTH');
            return;
          }
          if (data.data?.force_reset) {
            setStatus('FORCE_RESET');
            return;
          }
          setStorage('auth_token', data.data?.token);
          redirect(data);
        } else {
          setStatus('ERROR');
          setMessage(data.message);
          console.error('[ERROR]: ', data.message);
        }
      } catch (err) {
        setStatus('ERROR');
        setMessage(t('logIn.errors.technical'));
        console.error('[ERROR]: ', err);
      }
    }
  };

  const connectAsUser = async () => {
    const newErrors = validate();
    setErrors(newErrors);
    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors);
    } else {
      setStatus('LOADING');
      try {
        const { data } = await logIn(values);
        if (data.status !== 'KO') {
          setStatus('SUCCESS');
          if (data.data.refresh_token) {
            setStorage('auth_token', data.data.token);
            setStorage('auth_refresh', data.data.refresh_token, false);
          }
          if (data.data.double_authentication) {
            setStatus('DOUBLE_AUTH');
          } else if (values.emailAsUser && values.emailAsUser.trim() !== '') {
            const { data: dataAsUser } = await getUserToken(values.emailAsUser);
            if (dataAsUser.status === 'KO') {
              setStatus('ERROR');
              setMessage(t('logIn.errors.connectAsUserNotAuthorize'));
            } else {
              setStorage('auth_token', dataAsUser.data.token);
              setStorage('auth_refresh', dataAsUser.data.refresh_token, false);
              redirect(dataAsUser);
            }
          } else {
            redirect(data);
          }
        } else {
          setStatus('ERROR');
          setMessage(data.message);
        }
      } catch (err) {
        setStatus('ERROR');
        setMessage(t('logIn.errors.technical'));
      }
    }
  };

  const handleChange = (e) => {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
    setErrors((state) => {
      const newErrors = { ...state };
      delete newErrors[e.target.name];
      return newErrors;
    });
  };

  if (status === 'DOUBLE_AUTH') {
    return (
      <DoubleAuth email={values.email} as={values.emailAsUser} />
    );
  }

  if (status === 'FORCE_RESET') {
    return (
      <ForceReset reset={() => setStatus('DEFAULT')} />
    );
  }

  if (status === 'LOADING') {
    return <Loading />;
  }

  return (
    <section className={styles.logIn}>
      <p className={styles.logo}>{companyName}</p>
      <LogInForm
        status={status}
        message={message}
        errors={errors}
        values={values}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        connectAsUser={connectAsUser}
        disableCreateAccount={isCompany}
        brand={companyName}
      />
    </section>
  );
}

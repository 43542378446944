import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Password from './Password';
import validateMail from '../../utils/validateMail';
import { resetPassword } from '../../services/network/authentication';

export default function PasswordContainer() {
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState('IDLE');
  const [mail, setMail] = useState('');
  const [error, setError] = useState(null);
  const [recoveryMethod] = useState([]);

  const { t } = useTranslation();

  const validate = () => {
    if (!mail || mail.trim() === '') {
      setError(t('password.errors.mailEmpty'));
      return false;
    }
    if (!validateMail(mail)) {
      setError(t('password.errors.mailInvalid'));
      return false;
    }
    return true;
  };

  const askPasswordReset = async () => {
    if (validate()) {
      try {
        const { data } = await resetPassword(mail);
        if (data.status === 'OK') {
          setStatus('SUCCESS');
          setStep(2);
          window.setTimeout(() => setStatus('IDLE'), 2000);
        } else {
          setStatus('ERROR');
          setError(t('password.errors.mailInvalid'));
        }
      } catch (err) {
        setStatus('ERROR');
        setError(t('password.errors.technical'));
      }
    }
  };

  /* const askRecoveryLink = async (method) => {
    try {
      setStatus('LOADING');
      await sendRecoveryLink(method);
      if (step === 2) {
        setStatus('SUCCESS');
        window.setTimeout(() => setStatus('IDLE'), 2000);
      } else {
        setStatus('IDLE');
        setStep(2);
      }
    } catch (err) {
      setStatus('ERROR');
      setError(t('password.errors.technical'));
    }
  }; */

  const handleSubmit = async () => {
    if (step === 0 || step === 2) {
      await askPasswordReset();
    }
  };

  return (
    <Password
      step={step}
      status={status}
      mail={mail}
      handleChange={(e) => setMail(e.target.value)}
      handleSubmit={handleSubmit}
      error={error}
      recoveryMethod={recoveryMethod}
    />
  );
}

import React, { useState, useEffect } from 'react';
import { Search } from '@material-ui/icons';
import styles from '../UserDescription.module.css';
import rolesStyles from './Roles.module.css';
import Button from '../../../../../components/library/Button/Button';
import ExpandRole from './ExpandablesRoles';
import handleSearch from '../../../../../utils/recursiveSearch';

export default function Roles({
  selectedUser, allRoles, roles, setStatus, actions,
}) {
  /*
   * Deep copy of "allRoles" needed because reassign
   * to do recursive filter on child roles
   */
  const [allRolesFiltered, setAllRolesFiltered] = useState(JSON.parse(JSON.stringify(allRoles)));
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (allRoles.status === 'IDLE') {
      actions.fetchAllRoles();
    }
  }, []);

  useEffect(() => {
    if (!roles) {
      actions.fetchUserRoles(selectedUser.email);
    }
  }, [selectedUser.email]);

  useEffect(() => {
    setAllRolesFiltered(JSON.parse(JSON.stringify(allRoles)));
  }, [allRoles]);

  return (
    <section className={styles.userDescription}>
      <main className={styles.main}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>
              { selectedUser?.username }
            </h1>
          </div>
          <Button
            label="Manage user information"
            onButtonClick={() => setStatus('INFORMATION')}
          />
        </div>
        <section className={rolesStyles.section}>
          <header>
            <h2>
              Manage user&apos;s roles
            </h2>
          </header>
          <div className={rolesStyles.search}>
            <Search className={rolesStyles.icon} />
            <input
              type="text"
              placeholder="Start typing roles' name or groups' name"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setAllRolesFiltered(JSON.parse(JSON.stringify(allRoles)));
              }}
            />
          </div>
          <main>
            {
              allRolesFiltered && allRolesFiltered.roles
                .filter((role) => handleSearch(role, query))
                .map((role) => (
                  <ExpandRole
                    key={role.id}
                    role={role}
                    selected={roles}
                    add={actions.addUserRoles}
                    remove={actions.removeUserRoles}
                  />
                ))
            }
          </main>
        </section>
      </main>
    </section>
  );
}

import * as types from './types';
import { findAllChildRoles } from './actions';

const initState = {
  status: 'IDLE',
  creation: 'IDLE',
  update: 'IDLE',
  selectedRole: 'ROOT',
  lastSelectedRole: 'ROOT',
  roles: [],
  rawRoles: [],
};

const getSuccessState = (state, payload) => {
  if (!payload.parent) {
    const newRawRoles = [...state.rawRoles, ...payload.child];
    return {
      ...state,
      roles: newRawRoles.map((role) => findAllChildRoles(role, newRawRoles))
        .filter((role) => role.parents?.length === 0),
      rawRoles: newRawRoles,
      creation: 'SUCCESS',
      selectedRole: payload.child[0].name,
    };
  }
  const newRoles = [
    ...state.rawRoles
      .map((role) => ((role.name === payload.parent) ? { ...role, is_group: true } : role)),
    ...payload.child,
  ];
  return {
    ...state,
    roles: newRoles.map((role) => findAllChildRoles(role, newRoles))
      .filter((role) => role.parents?.length === 0),
    rawRoles: newRoles,
    creation: 'SUCCESS',
  };
};

const getStateAfterDeletion = (state, payload) => {
  if (payload.parent) {
    const newRawRoles = state.rawRoles
      .map((role) => (payload.name.includes(role.name)
        ? { ...role, parents: role.parents.filter((parent) => (parent !== payload.parent)) }
        : role));
    return {
      ...state,
      deletion: 'SUCCESS',
      selectedRole: (!payload.name.includes(state.selectedRole)) ? state.selectedRole : null,
      rawRoles: newRawRoles,
      roles: newRawRoles.map((role) => findAllChildRoles(role, newRawRoles))
        .filter((role) => (role.parents?.length === 0)),
    };
  }
  return {
    ...state,
    deletion: 'SUCCESS',
    selectedRole: (!payload.name.includes(state.selectedRole)) ? state.selectedRole : null,
    rawRoles: state.rawRoles.filter((role) => !payload.name.includes(role.name)),
    roles: state.roles.map((role) => findAllChildRoles(
      role,
      state.rawRoles.filter((r) => !payload.name.includes(r.name)),
    ))
      .filter((role) => (role.parents?.length === 0 && !payload.name.includes(role?.name))),
  };
};

const getStateAfterUpdate = (state, payload) => {
  const rawRoles = state.rawRoles.map((role) => ((role.name === payload)
    ? { ...role, default: !role.default }
    : role));
  const newRoles = rawRoles
    .map((role) => (findAllChildRoles(role, rawRoles)))
    .filter((role) => role.parents.length === 0);
  return {
    ...state,
    update: 'SUCCESS',
    rawRoles,
    roles: newRoles,
  };
};

const getStateAfterAddingChild = (state, payload) => {
  const { parent, child } = payload;
  const newRawRoles = state.rawRoles.map((role) => {
    if (child.map((c) => c.name).includes(role.name)) {
      return { ...role, parents: [...role.parents, parent] };
    }
    if (role.name === parent) {
      return { ...role, is_group: true };
    }
    return role;
  });
  return ({
    ...state,
    add: 'SUCCESS',
    rawRoles: newRawRoles,
    roles: newRawRoles.map((role) => findAllChildRoles(role, newRawRoles))
      .filter((role) => role.parents?.length === 0),
  });
};

export default function adminReducer(state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.FETCH_ALL_ROLES_REQUEST:
      return { ...state, status: 'LOADING' };
    case types.FETCH_ALL_ROLES_SUCCEEDED:
      return {
        ...state, status: 'SUCCESS', roles: payload.parsed, rawRoles: payload.raw,
      };
    case types.FETCH_ALL_ROLES_FAILED:
      return { ...state, status: 'ERROR' };
    case types.SET_SELECTED_ROLES:
      return {
        ...state, selectedRole: payload, lastSelectedRole: state.selectedRole, creation: 'IDLE',
      };
    case types.RESET_ROLES_CREATION:
      return {
        ...state, creation: 'IDLE',
      };
    case types.CREATE_ROLES_REQUEST:
      return { ...state, creation: 'LOADING' };
    case types.CREATE_ROLES_SUCCEEDED:
      return getSuccessState(state, payload);
    case types.CREATE_ROLES_FAILED:
      return { ...state, creation: 'ERROR', error: `${payload}` };
    case types.DELETE_ROLES_REQUEST:
      return { ...state, deletion: 'LOADING' };
    case types.DELETE_ROLES_SUCCEEDED:
      return getStateAfterDeletion(state, payload);
    case types.ADD_CHILDS_REQUEST:
      return { ...state, add: 'LOADING' };
    case types.ADD_CHILDS_SUCCEEDED:
      return getStateAfterAddingChild(state, payload);
    case types.ADD_CHILDS_FAILED:
      return { ...state, add: 'ERROR', error: `${payload}` };
    case types.ADD_CHILDS_RESET:
      return { ...state, add: 'IDLE' };
    case types.SET_ROLE_DEFAULT_REQUEST:
      return { ...state, update: 'LOADING' };
    case types.SET_ROLE_DEFAULT_RESET:
      return { ...state, update: 'IDLE' };
    case types.SET_ROLE_DEFAULT_FAILED:
      return { ...state, update: 'ERROR', error: payload };
    case types.SET_ROLE_DEFAULT_SUCCEEDED:
      return getStateAfterUpdate(state, payload);
    default:
      return state;
  }
}

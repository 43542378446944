import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SecurityAccount from './SecurityAccount';
import {
  deleteSelectedUserAccount,
  validateSelectedUserAccount,
} from '../../../../../../../services/store/features/users/actions';

const mapStateToProps = (state) => ({
  roles: state.roles.roles,
  validate: state.users.users.find((user) => user.email === state.users.selectedUser).validate,
  status: state.users.validate,
  error: state.users.error,
  deletion: state.users.deletion,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    validateSelectedUserAccount,
    deleteSelectedUserAccount,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityAccount);

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import { ErrorSharp, FileCopy } from '@material-ui/icons';
import Button from '../../components/library/Button/Button';
import copyTextToClipboard from '../../utils/copyToClipboard';
import styles from './Debug.module.css';
import shared from '../../styles/shared.module.css';
import DebugDecodedToken from './DebugDecodedToken';
import { getStorage } from '../../utils/saveLocal';

export default function Debug() {
  const [decodedToken, setDecodedToken] = useState(null);
  const [decodedRefreshToken, setDecodedRefreshToken] = useState(null);
  const [tokenCopyStatus, setTokenCopyStatus] = useState('IDLE');
  const [refreshTokenCopyStatus, setRefreshTokenCopyStatus] = useState('IDLE');
  const [error, setError] = useState({});

  const handleCopyState = (status, setStatus) => {
    setStatus(status);
    window.setTimeout(() => setStatus('IDLE'), 1000);
  };

  useEffect(async () => {
    const { data } = await axios.get(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/api/auth/cert`);
    const { cert } = data.data;
    const token = getStorage('auth_token');
    const refreshToken = getStorage('auth_refresh', false);
    jwt.verify(token, cert, (err, decoded) => {
      if (!err) {
        setDecodedToken(decoded);
      } else {
        setError((state) => ({ ...state, token: err }));
      }
    });
    jwt.verify(refreshToken, cert, (err, decoded) => {
      if (!err) {
        setDecodedRefreshToken(decoded);
      } else {
        setError((state) => ({ ...state, refreshToken: err }));
      }
    });
  }, []);
  return (
    <section className={styles.section}>
      <p className={shared.logo}>
        DEBUG
      </p>
      <div className={styles.container}>
        <div className={styles.tokenWrapper}>
          <div className={styles.row}>
            <p className={styles.title}>
              Token - Encoded
            </p>
            <Button
              label={(tokenCopyStatus === 'SUCCESS') ? 'copied !' : 'Copy the token'}
              type="custom"
              size="small"
              className={styles.copy}
              icon={<FileCopy />}
              onButtonClick={() => copyTextToClipboard(
                getStorage('auth_token'),
                () => handleCopyState('SUCCESS', setTokenCopyStatus),
                () => handleCopyState('ERROR', setTokenCopyStatus),
              )}
            />
          </div>
          <div className={styles.token}>
            <p>
              {getStorage('auth_token')?.split('.').join('.\n') || 'Aucun token detecté'}
            </p>
          </div>
        </div>
        <div className={styles.decodedTokenWrapper}>
          <div>
            <p className={styles.title}> Decoded token </p>
            {
              (error.token)
              && (
                <p className={styles.error}>
                  <ErrorSharp className={styles.icon} />
                  { `${error.token}` }
                </p>
              )
            }
            <DebugDecodedToken
              decodedToken={decodedToken}
            />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.tokenWrapper}>
          <div className={styles.row}>
            <p className={styles.title}>
              Refresh Token - encoded
            </p>
            <Button
              label={(refreshTokenCopyStatus === 'SUCCESS') ? 'copied !' : 'Copy the token'}
              type="custom"
              size="small"
              className={styles.copy}
              icon={<FileCopy />}
              onButtonClick={() => copyTextToClipboard(
                getStorage('auth_refresh', false),
                () => handleCopyState('SUCCESS', setRefreshTokenCopyStatus),
                () => handleCopyState('ERROR', setRefreshTokenCopyStatus),
              )}
            />
          </div>
          <div className={styles.token}>
            <p>
              {getStorage('auth_refresh', false)?.split('.').join('.\n') || 'Aucun refresh token detecté'}
            </p>
          </div>
        </div>
        <div className={styles.tokenWrapper}>
          <div>
            <p className={styles.title}> Decoded refresh token </p>
            {
              (error.refreshToken)
              && (
                <p className={styles.error}>
                  <ErrorSharp className={styles.icon} />
                  { `${error.refreshToken}` }
                </p>
              )
            }
            <DebugDecodedToken
              decodedToken={decodedRefreshToken}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

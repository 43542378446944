function fallbackCopyTextToClipboard(text, callbackSuccess, callbackError) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    if (callbackSuccess) callbackSuccess(msg);
  } catch (err) {
    if (callbackError) callbackError(err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text, callbackSuccess, callbackError) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(() => {
    if (callbackSuccess) callbackSuccess();
  }, (err) => {
    if (callbackError) callbackError(err);
  });
}

export default copyTextToClipboard;

import React, { useState } from 'react';
import { CheckCircle, Delete, Error } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../../../components/library/Button/Button';
import styles from './SecurityAccount.module.css';
import { isUserAuthorized } from '../../../../../../../services/store/features/roles/actions';

export default function SecurityAccount({
  roles, validate, deletion, status, error, isEditable, actions,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleValidateAccount = async () => {
    actions.validateSelectedUserAccount();
  };

  const handleDeletion = () => {
    actions.deleteSelectedUserAccount();
  };

  return (
    <>
      <p className={styles.label}>
        Validate the user account
      </p>
      {
        (status === 'FAIL')
        && (
        <div className={`bounceIn ${styles.status} ${styles.error}`}>
          <p className={styles.title}>
            <span className={styles.icon}>
              <Error />
            </span>
            <span>
              Account has not been validated
            </span>
          </p>
          <p className={styles.description}>
            { error }
          </p>
        </div>
        )
      }
      {
        (validate || status === 'SUCCESS')
        && (
          <div className={`bounceIn ${styles.status} ${styles.success}`}>
            <p className={styles.title}>
              <span className={styles.icon}>
                <CheckCircle />
              </span>
              <span>
                Account has been already validated
              </span>
            </p>
          </div>
        )
      }
      {
        (!validate)
        && (
          <Button
            label="Validate the account"
            onButtonClick={handleValidateAccount}
            icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
            disabled={status === 'LOADING'}
          />
        )
      }
      {
        (isUserAuthorized(roles, ['AUTH_USERS_DELETE']))
        && (
          <>
            {
              (isOpen)
              && (
                <div className={styles.background}>
                  <section className={styles.modal}>
                    <p className={styles.title}>
                      Are you sure you want to permanently delete this account ?
                    </p>
                    <p className={styles.description}>
                      You can&apos;t undo this action.
                    </p>
                    <div className={styles.buttonGroup}>
                      <Button
                        label="Cancel"
                        type="custom"
                        className={styles.custom}
                        onButtonClick={() => setIsOpen(false)}
                      />
                      <Button
                        label="Delete account"
                        type="primary"
                        onButtonClick={handleDeletion}
                        icon={(deletion === 'LOADING') ? <FontAwesomeIcon icon={faSpinner} spin /> : <Delete />}
                        disabled={deletion === 'LOADING'}
                      />
                    </div>
                  </section>
                </div>
              )
            }
            <p className={styles.label}>
              Delete user profile
            </p>
            {
              (deletion === 'FAIL')
              && (
                <div className={`bounceIn ${styles.status} ${styles.error}`}>
                  <p className={styles.title}>
                    <span className={styles.icon}>
                      <Error />
                    </span>
                    <span>
                      Account has not been deleted
                    </span>
                  </p>
                  <p className={styles.description}>
                    { error }
                  </p>
                </div>
              )
            }
            <Button
              label="Delete account"
              type="primary"
              onButtonClick={() => setIsOpen(true)}
              icon={(deletion === 'LOADING') ? <FontAwesomeIcon icon={faSpinner} spin /> : <Delete />}
              disabled={!isEditable || deletion === 'LOADING'}
            />
          </>
        )
      }
    </>
  );
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Roles from './Roles';
import { addUserRoles, fetchUserRoles, removeUserRoles } from '../../../../../services/store/features/users/actions';
import { fetchAllRoles } from '../../../../../services/store/features/admin/actions';

const mapStateToProps = (state) => ({
  roles: state.users.users.find((user) => user.email === state.users.selectedUser).roles,
  allRoles: state.admin,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchUserRoles,
    fetchAllRoles,
    addUserRoles,
    removeUserRoles,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);

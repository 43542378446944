import Applications from '../../views/Applications';
import Account from '../../views/Account';
import Password from '../../views/Password';
import Confirm from '../../views/Confirm';
import LogIn from '../../views/LogIn';

const ROUTES_USERS = [
  {
    path: '/applications',
    component: Applications,
    auth: true,
  },
  {
    path: '/create-account',
    component: Account,
  },
  {
    path: '/password',
    component: Password,
  },
  {
    path: '/confirm',
    component: Confirm,
  },
  {
    path: '/',
    component: LogIn,
  },
];

export default ROUTES_USERS;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RolesDescription from './RolesDescription';
import { setSelectedRoles, deleteRoles, setDefaultRoleStatus } from '../../../../services/store/features/admin/actions';

function findByName(roles, name) {
  let result;
  function iter(role) {
    if (role.name === name) {
      result = role;
      return true;
    }
    return Array.isArray(role.child) && role.child.some(iter);
  }
  roles.some(iter);
  return result;
}

const mapStateToProps = (state) => ({
  role: findByName(state.admin.roles, state.admin.selectedRole),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setSelectedRoles,
    deleteRoles,
    setDefaultRoleStatus,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesDescription);

import axiosDefault from 'axios';
import axios from './axiosClient';

export const axiosDefaultClient = axiosDefault.create({
  baseURL: '/api',
  timeout: 60000,
});

export const logIn = (values) => axiosDefaultClient.post('/auth/password', values);
export const confirmAccount = (email) => axiosDefaultClient.get(`/users/${email}/confirm`);

export const logOut = () => axios.post('/logout');
export const resetPassword = (email) => axios.post(`/users/${email}/reset`);
export const sendRecoveryLink = (value) => axios.post('/recovery-link', value);
export const createAccount = (values) => axios.post('/users/', values);
export const validateAccount = (email) => axios.get(`/users/${email}/confirm`);
export const deleteAccount = (email) => axios.delete(`/users/${email}`);
export const getUserApplications = () => axios.get('/applications');

export const createNewRoles = (role) => axios.post('/roles', role);
export const getAllRoles = () => axios.get('/roles/all');
export const addChildsToRoles = (role, childs) => axios.post(`/roles/${role}/childs`, { childs });
export const deleteRolesByName = (name, parent) => {
  if (parent) {
    return axios.put(`/roles/${parent}/childs`, { childs: name });
  }
  return axios({ method: 'delete', url: '/roles', data: { roles: name } });
};
export const updateRoleDefaultStatus = (role) => axios.post(`/roles/${role}/default`);

export const getUserToken = (email) => axios.post('/auth/token', { email });

export const getUsersList = () => axios.get('/users/all');
export const getRolesByUser = (email) => axios.get(`/users/${email}`);
export const updateUserByEmail = (email, values) => axios.post(`/users/${email}`, values);

export const addRolesToUser = (email, values) => axios.post(`/users/${email}/roles`, { roles: values });
export const deleteRolesToUser = (email, values) => axios({ method: 'delete', url: `/users/${email}/roles`, data: { roles: values } });

import { useState, useEffect } from 'react';

const useForm = (callback, validate, initialValues = {}) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (errors && Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
      setIsSubmitting(false);
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsSubmitting(true);
    setErrors(validate(values));
  };

  const handleChange = (event, newValue) => {
    event.persist();
    setValues((state) => ({
      ...state,
      [event.target.name]: event.target.value || newValue,
    }));
    setErrors((state) => {
      const { [event.target.name]: omit, ...withoutKey } = state;
      return withoutKey;
    });
  };

  const handleBlur = (name, error) => {
    setErrors((state) => ({
      ...state,
      [name]: error,
    }));
  };

  const setNewValue = (name, newValue) => {
    setValues((state) => ({
      ...state,
      [name]: newValue,
    }));
  };

  return {
    values,
    setNewValue,
    setValues,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
  };
};

export default useForm;

import { connect } from 'react-redux';

import UserDescription from './UserDescription';

const mapStateToProps = (state) => {
  const selectedUser = state.users.users
    .find((user) => user.email === state.users.selectedUser) || null;
  if (selectedUser) {
    return ({
      selectedUser,
      isEditable: !selectedUser.roles?.includes('AUTH_ADMIN') || state.roles.roles?.includes('AUTH_ADMIN'),
    });
  }
  return ({
    selectedUser: state.users.users
      .find((user) => user.email === state.users.selectedUser) || null,
    isEditable: false,
  });
};

export default connect(mapStateToProps)(UserDescription);

import React, { useEffect, useState } from 'react';

import { Add, Search } from '@material-ui/icons';
import styles from './RolesList.module.css';
import Button from '../../../../components/library/Button/Button';
import ExpandRoles from './ExpandablesRoles';
import handleSearch from '../../../../utils/recursiveSearch';

export default function RolesList({
  status, roles, selected, open, actions,
}) {
  const [query, setQuery] = useState('');
  const [allRolesFiltered, setAllRolesFiltered] = useState(JSON.parse(JSON.stringify(roles)));

  useEffect(() => {
    if (status === 'IDLE') {
      actions.fetchAllRoles();
    }
  }, []);

  useEffect(() => {
    setAllRolesFiltered(JSON.parse(JSON.stringify(roles)));
  }, [roles]);

  return (
    <section className={styles.sidebar}>
      <header>
        <div className={styles.searchbar}>
          <Search />
          <input
            className={styles.input}
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setAllRolesFiltered(JSON.parse(JSON.stringify(roles)));
            }}
          />
        </div>
        <Button
          label=""
          icon={<Add />}
          onButtonClick={() => open('NEW')}
        />
      </header>
      <ul className={styles.list}>
        {
          allRolesFiltered && allRolesFiltered
            .filter((role) => handleSearch(role, query))
            .map((role) => (
              <ExpandRoles
                key={role.id}
                role={role}
                selected={selected}
                isChild={false}
                handleSelection={actions.setSelectedRoles}
                open={(query && query !== '')}
              />
            ))
        }
      </ul>
    </section>
  );
}

import Admin from '../../views/Admin';
import Debug from '../../views/Debug';
import UserAuth from '../../views/UserAuth';
import Disconnect from '../../views/Disconnect';

const ROUTES = [
  {
    path: '/admin',
    component: Admin,
    auth: true,
    roles: ['AUTH_MANAGER'],
  },
  {
    path: '/debug',
    component: Debug,
    auth: true,
  },
  {
    path: '/disconnect',
    component: Disconnect,
    auth: true,
  },
  {
    path: '/',
    component: UserAuth,
  },
];

export default ROUTES;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../UserDescription.module.css';
import informationStyles from './Information.module.css';
import Button from '../../../../../components/library/Button/Button';
import Security from './Security';
import Personal from './Personal';
import { isUserAuthorized } from '../../../../../services/store/features/roles/actions';
import { getUserToken } from '../../../../../services/network/authentication';
import LogInPopUp from './LogInPopUp';

export default function Information({
  selectedUser, userRoles, roles, setStatus, isEditable, actions,
}) {
  const [loginStatus, setLoginStatus] = useState('IDLE');
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    actions.resetUpdate();
    if (!userRoles) {
      actions.fetchUserRoles(selectedUser.email);
    }
  }, []);

  const loginAsUser = async () => {
    setLoginStatus('LOADING');
    try {
      const { data } = await getUserToken(selectedUser.email);
      if (data.status === 'KO') {
        setLoginStatus('ERROR');
        setError(t('logIn.errors.connectAsUserNotAuthorize'));
      } else {
        setLoginStatus('SUCCESS');
        setToken(data.data.refresh_token);
      }
    } catch (err) {
      setLoginStatus('ERROR');
      setError(err);
      setToken(null);
    }
  };

  const isSelectedUserSuperior = () => {
    if (userRoles && isUserAuthorized(userRoles, ['AUTH_ADMIN'])) {
      return !(roles && isUserAuthorized(roles, ['AUTH_ADMIN']));
    }
    return false;
  };

  return (
    <section className={styles.userDescription}>
      {
        (loginStatus !== 'IDLE')
        && (
        <LogInPopUp
          status={loginStatus}
          close={() => setLoginStatus('IDLE')}
          error={error}
          url={`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/?refresh_token=${token}`}
        />
        )
      }
      <main className={styles.main}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>
              { selectedUser?.username }
            </h1>
          </div>
          <div className={informationStyles.row}>
            {
              (isUserAuthorized(roles, ['AUTH_AS_USER'])
                && !isSelectedUserSuperior()
                && (
                  <Button
                    variant="outline"
                    label={`Login as ${selectedUser?.username}`}
                    onButtonClick={loginAsUser}
                  />
                ))
            }
            {
              (isUserAuthorized(roles, ['AUTH_ROLES'])
                && (
                  <Button
                    label="Manage user roles"
                    onButtonClick={() => setStatus('ROLES')}
                  />
                ))
            }
          </div>
        </div>
        { selectedUser && (
          <>
            <Personal
              key={selectedUser.id}
              selectedUser={selectedUser}
              roles={roles}
              isEditable={isEditable}
            />
            <Security user={selectedUser} roles={roles} isEditable={isEditable} />
          </>
        )}
      </main>
    </section>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import InputText from '../../components/library/Input/InputText/InputText';
import Button from '../../components/library/Button/Button';
import styles from './Password.module.css';
import shared from '../../styles/shared.module.css';
import Checkbox from '../../components/library/Input/Checkbox/Checkbox';
import { BRAND } from '../../global';

export default function Password({
  step = 0, status, mail, handleChange, handleSubmit, error, recoveryMethod,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [method, setMethod] = useState('mail');

  const getButtonLabel = () => {
    if (status === 'SUCCESS') {
      return t('password.recoveryLinkSend');
    }
    return (status === 'LOADING') ? t('password.loading') : t(`password.reset${method}RecoveryLink`);
  };

  const getButtonIcon = () => {
    if (status === 'SUCCESS') {
      return <FontAwesomeIcon icon={faCheck} />;
    }
    if (status === 'LOADING') {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    return null;
  };

  if (step === 1) {
    return (
      <section className={`fadeIn ${styles.password}`}>
        <p className={shared.logo}>
          {BRAND}
        </p>
        <h1 className={styles.title}>
          {t('password.recovery')}
        </h1>
        <p className={styles.description}>
          {t('password.recoveryDescription')}
        </p>
        <div className={styles.checkboxContainer}>
          {
            recoveryMethod.includes('phone')
            && (
              <Checkbox
                label={t('password.recoverySms')}
                checked={(method === 'phone')}
                onChange={(bool) => {
                  if (bool) {
                    setMethod('phone');
                  } else {
                    setMethod('mail');
                  }
                }}
              />
            )
          }
          <Checkbox
            label={t('password.recoveryMail')}
            checked={(method === 'mail')}
            onChange={(bool) => {
              if (bool) {
                setMethod('mail');
              }
            }}
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            label={t('password.reset')}
            name="reset"
            onButtonClick={() => handleSubmit({ method })}
          />
          <Button
            label={t('password.cancel')}
            variant="ghost"
            name="cancel"
            onButtonClick={() => history.push('/')}
          />
        </div>
      </section>
    );
  }

  if (step === 2) {
    return (
      <section className={`fadeIn ${styles.password}`}>
        <p className={shared.logo}>
          {BRAND}
        </p>
        <h1 className={styles.title}>
          {t(`password.recovery${method}Success`)}
        </h1>
        <p className={styles.description}>
          {t(`password.recovery${method}Description`)}
        </p>
        <div className={styles.buttonGroup}>
          <Button
            label={getButtonLabel()}
            name="reset"
            icon={getButtonIcon()}
            onButtonClick={() => handleSubmit({ method })}
          />
          <Button
            label={t('password.back')}
            variant="ghost"
            name="back"
            onButtonClick={() => history.push('/')}
          />
        </div>
      </section>
    );
  }
  return (
    <section className={styles.password}>
      <p className={shared.logo}>
        {BRAND}
      </p>
      <h1 className={styles.title}>
        {t('password.title')}
      </h1>
      <p className={styles.description}>
        {t('password.description')}
      </p>
      <InputText
        label={t('logIn.form.mail')}
        state={(error) ? 'ERROR' : 'DEFAULT'}
        hint={(status !== 'ERROR') && error}
        name="mail"
        value={mail}
        onTextChange={handleChange}
        placeholder={t('logIn.form.mail')}
      />
      {
        (status === 'ERROR' && error)
        && (
          <p className={styles.error}>
            { error }
          </p>
        )
      }
      <div className={styles.buttonGroup}>
        <Button
          label={t('password.reset')}
          name="reset"
          onButtonClick={handleSubmit}
        />
        <Button
          label={t('password.cancel')}
          variant="ghost"
          name="cancel"
          onButtonClick={() => history.push('/')}
        />
      </div>
    </section>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from '../../UserDescription.module.css';
import personalStyles from './Personal.module.css';
import Button from '../../../../../../components/library/Button/Button';
import InputText from '../../../../../../components/library/Input/InputText/InputText';
import InputPhone from '../../../../../../components/library/Input/InputPhone/InputPhone';
import useForm from '../../../../../../hooks/useForm';
import validateMail from '../../../../../../utils/validateMail';
import { isUserAuthorized } from '../../../../../../services/store/features/roles/actions';

export default function Personal({
  selectedUser, roles, status, error, isEditable, actions,
}) {
  const validate = (values) => {
    const errors = {};
    if (!values.email || values.email.trim() === '') {
      errors.email = 'Email is required';
    }
    if (values.email && !validateMail(values.email)) {
      errors.email = 'Invalid email';
    }
    if (!values.firstname || values.firstname.trim() === '') {
      errors.firstname = 'Firstname is required';
    }
    if (!values.lastname || values.lastname.trim() === '') {
      errors.lastname = 'Lastname is required';
    }
    if (!values.phone || values.phone.trim() === '') {
      errors.phone = 'Phone is required';
    }
    return errors;
  };

  const submit = async (newUser) => {
    const {
      firstname, lastname, phone, email,
    } = newUser;
    actions.updateSelectedUser({
      firstname, lastname, phone, email,
    });
  };
  const {
    values, errors, handleSubmit, handleChange, setNewValue,
  } = useForm(submit, validate, selectedUser);

  return (
    <section key={selectedUser} className={styles.section}>
      <header>
        <h2>
          Personal information
        </h2>
        {
          (isUserAuthorized(roles, ['AUTH_USERS_EDIT']) && isEditable)
          && (
            <Button
              label="Save changes"
              icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
              variant="ghost"
              onButtonClick={handleSubmit}
            />
          )
        }
      </header>
      {
        (status === 'SUCCESS')
        && (
          <p className={personalStyles.success}>
            Update successful !
          </p>
        )
      }
      {
        (status === 'ERROR')
        && (
          <p className={personalStyles.error}>
            {' '}
            { error }
          </p>
        )
      }
      <form className={styles.form}>
        <InputText
          label="Firstname"
          name="firstname"
          valueInput={values?.firstname || ''}
          state={(errors?.firstname) ? 'ERROR' : 'DEFAULT'}
          hint={errors?.firstname}
          onTextChange={handleChange}
          disabled={!isUserAuthorized(roles, ['AUTH_USERS_EDIT']) || !isEditable}
        />
        <InputText
          label="Lastname"
          name="lastname"
          valueInput={values?.lastname || ''}
          state={(errors?.lastname) ? 'ERROR' : 'DEFAULT'}
          hint={errors?.lastname}
          onTextChange={handleChange}
          disabled={!isUserAuthorized(roles, ['AUTH_USERS_EDIT']) || !isEditable}
        />
        <InputText
          label="Email"
          name="email"
          valueInput={values?.email || ''}
          hint={errors?.email}
          state={(errors?.email) ? 'ERROR' : 'DEFAULT'}
          onTextChange={handleChange}
          disabled={!isUserAuthorized(roles, ['AUTH_USERS_EDIT']) || !isEditable}
        />
        {
          (isEditable && isUserAuthorized(roles, ['AUTH_USERS_EDIT']))
            ? (
              <InputPhone
                label="Phone"
                name="phone"
                phone={values?.phone || ''}
                state={(errors?.phone) ? 'ERROR' : 'DEFAULT'}
                hint={errors?.phone}
                setPhone={(phone) => {
                  setNewValue('phone', phone);
                }}
              />
            )
            : (
              <InputText
                label="Phone"
                name="phone"
                valueInput={values?.phone || ''}
                disabled
              />
            )
        }
      </form>
    </section>
  );
}

import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { CheckCircle, Search } from '@material-ui/icons';
import styles from './UsersList.module.css';

export default function UsersList({
  status, users, selectedUser, actions,
}) {
  const MAX_PER_PAGE = 25;

  const [query, setQuery] = useState();
  const [page, setPage] = useState(1);
  const [displayedList, setDisplayedList] = useState(users.slice(0, page * MAX_PER_PAGE));

  const observer = useRef();
  const lastUser = useCallback(
    (node) => {
      if (status === 'LOADING') return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && (users.length > page * MAX_PER_PAGE)) {
          setPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [users],
  );

  useEffect(() => {
    if (status === 'IDLE') {
      actions.fetchUsers();
    }
  }, []);

  useEffect(() => {
    setDisplayedList(users.slice(0, page * MAX_PER_PAGE));
  }, [users]);

  const getActiveUser = (value) => users
    .filter((user) => {
      if (!value || value.trim() === '') return true;
      const userToString = `${user.firstname} ${user.lastname} ${user.username} ${user.email} ${(value.length >= 10) ? user.phone : ''} {id: ${user.id}}`.toLowerCase();
      return userToString.search(value.toLowerCase()) !== -1;
    });

  useEffect(() => {
    setDisplayedList(getActiveUser(query).slice(0, page * MAX_PER_PAGE));
  }, [query, page]);

  const getValidateClassName = (user) => {
    if (user.tmp === 1) return styles.orange;
    if (user.validate === 1) return styles.green;
    return styles.red;
  };

  const handleChange = (e) => {
    setPage(1);
    setQuery(e.target.value);
  };

  return (
    <section className={styles.usersList}>
      <div className={styles.inputWrapper}>
        <div className={styles.searchbar}>
          <Search />
          <input
            className={styles.input}
            type="text"
            placeholder="Search"
            onChange={handleChange}
          />
        </div>
      </div>
      <p className={styles.users}>
        {`Number of users : ${(query) ? getActiveUser(query).length : users.length}`}
      </p>
      <ul className={styles.list}>
        {
          displayedList
            && displayedList
              .map((user, i) => {
                const isLastUser = displayedList.length === i + 1;
                return (
                  <div
                    key={user.id}
                    role="button"
                    tabIndex={-1}
                    className={`${styles.item} ${(selectedUser === user.email) ? styles.selected : ''}`}
                    onClick={() => actions.setSelectedUser(user.email)}
                    onKeyDown={() => actions.setSelectedUser(user.email)}
                    ref={(isLastUser) ? lastUser : null}
                  >
                    <p className={styles.title}>
                      {user.username}
                      <CheckCircle className={`${styles.icon} ${getValidateClassName(user)}`} />
                    </p>
                    <p className={styles.description}>
                      {`${user.email} ( id: ${user.id} )`}
                    </p>
                  </div>
                );
              })
        }
      </ul>
    </section>
  );
}

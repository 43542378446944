import React, { useState, useEffect, useRef } from 'react';
import { CheckCircle, Search } from '@material-ui/icons';
import styles from '../RoleForm.module.css';
import Button from '../../../../../components/library/Button/Button';
import useOutsideClickAlert from '../../../../../components/library/hook/clickOutside';

export default function RoleFormChild({
  status, selected, creation, error, roles, close, actions,
}) {
  const [query, setQuery] = useState('');
  const [child, setChild] = useState([]);
  const [hasSubmit, setHasSubmit] = useState(false);

  const modalRef = useRef(null);
  useOutsideClickAlert(modalRef, close);

  useEffect(() => {
    actions.resetAddChildRequest();
    if (status === 'IDLE') {
      actions.fetchAllRoles();
    }
  }, []);

  useEffect(() => {
    if (hasSubmit && creation === 'SUCCESS') {
      close();
    }
  }, [creation]);

  const handleSubmit = () => {
    setHasSubmit(true);
    actions.addExistingRolesAsChild({ parent: selected, child });
    setChild([]);
  };

  return (
    <div className={styles.overlay}>
      <section className={styles.popup} ref={modalRef}>
        <header className={styles.header}>
          <h3>
            Add a role as child
          </h3>
          {
                (selected !== 'NEW')
                && (
                  <p className={styles.create}>
                    {'Add childs for '}
                    <span>
                      { selected }
                    </span>
                  </p>
                )
             }
        </header>
        {
             (creation === 'ERROR' && error)
             && <p className={styles.error}>{`${error}`}</p>
          }
        {
             (creation === 'SUCCESS')
             && (
               <p className={styles.success}>
                 <CheckCircle className={styles.icon} />
                 Subroles has been successfully added
               </p>
             )
          }
        <form className={styles.form}>
          <p className={styles.bold}>
            Roles
          </p>
          <div className={styles.searchbar}>
            <Search />
            <input
              className={styles.input}
              type="text"
              placeholder="Search"
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
          </div>
          <ul className={styles.list}>
            {
                   roles && roles
                     .filter((role) => (
                       (role.name !== selected)
                       && !role.parents.includes(selected)
                       && (role.name?.includes(query.toUpperCase()))
                     ))
                     .map((role) => (
                       <li key={role.id}>
                         <p>
                           { role.name }
                         </p>
                         {
                             (child.map((c) => c.name).includes(role.name))
                               ? (
                                 <Button
                                   label="Added"
                                   size="small"
                                   type="custom"
                                   className={styles.selected}
                                   onButtonClick={() => setChild(
                                     (state) => state.filter((r) => r.name !== role.name),
                                   )}
                                 />
                               )
                               : (
                                 <Button
                                   label="Add as child"
                                   size="small"
                                   onButtonClick={() => setChild((state) => Array.from(new Set([
                                     ...state, role,
                                   ])))}
                                 />
                               )

                          }

                       </li>
                     ))
                }
          </ul>
        </form>
        <footer className={styles.footer}>
          <Button
            label="Cancel"
            type="custom"
            className={styles.cancel}
            onButtonClick={close}
          />
          <Button
            label={`Add ${child.length} roles as child`}
            onButtonClick={handleSubmit}
            disabled={child.length === 0}
          />
        </footer>
      </section>
    </div>
  );
}

import * as types from './types';
import {
  getAllRoles, createNewRoles, addChildsToRoles, deleteRolesByName, updateRoleDefaultStatus,
} from '../../../network/authentication';

let id = 0;

export function findAllChildRoles(role, data) {
  id = (role.id > id) ? role.id : id;
  if (!role?.is_group) {
    return role;
  }
  const child = data.reduce((acc, curr) => {
    if (curr?.parents?.includes(role.name)) {
      const children = findAllChildRoles(curr, data);
      return [...acc, children];
    }
    return acc;
  }, []);
  if (child.length === 0) {
    return { ...role, is_group: false };
  }
  return {
    ...role,
    child,
  };
}

export function fetchAllRoles() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_ALL_ROLES_REQUEST });
    try {
      const { data } = await getAllRoles();
      if (data.status === 'OK') {
        const newData = data.data
          .map((role) => (findAllChildRoles(role, data.data)))
          .filter((role) => role.parents.length === 0);
        dispatch({
          type: types.FETCH_ALL_ROLES_SUCCEEDED,
          payload: { parsed: newData, raw: data.data },
        });
      } else {
        dispatch({ type: types.FETCH_ALL_ROLES_FAILED, payload: data.msg });
      }
    } catch (err) {
      console.error('[ERROR]: ', err);
      dispatch({ type: types.FETCH_ALL_ROLES_FAILED, payload: err });
    }
  };
}

export function setSelectedRoles(name) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SELECTED_ROLES, payload: name });
  };
}

export function resetRoleCreation() {
  return (dispatch) => {
    dispatch({ type: types.RESET_ROLES_CREATION });
  };
}

const createRole = (
  name, parent = null, isGroup = false, isDefault = false,
) => {
  id += 1;
  return {
    id,
    name,
    default: isDefault,
    is_group: isGroup,
    child: [],
    parents: (parent) ? [parent] : [],
  };
};

export function createNewRolesByName(newRole, reset = true) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_ROLES_REQUEST });
    try {
      const { data } = await createNewRoles(newRole);
      if (data.status === 'OK') {
        dispatch({
          type: types.CREATE_ROLES_SUCCEEDED,
          payload: {
            parent: null,
            child: [createRole(newRole.name, null, false, newRole.default)],
          },
        });
        if (reset) {
          dispatch({ type: types.RESET_ROLES_CREATION });
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: types.CREATE_ROLES_FAILED, payload: err });
    }
  };
}

export function addChildToRolesByName(name, child) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_ROLES_REQUEST });
    const arrayOfChild = child.map((role) => createRole(role, name));
    try {
      const { data } = await addChildsToRoles(name, child);
      if (data.status === 'OK') {
        dispatch({
          type: types.CREATE_ROLES_SUCCEEDED,
          payload: { child: arrayOfChild, parent: name },
        });
      }
    } catch (err) {
      dispatch({ type: types.CREATE_ROLES_FAILED, payload: err });
    }
  };
}

export function createNewRoleAndAddChild(name, parent) {
  return async (dispatch) => {
    if (parent) {
      await dispatch(addChildToRolesByName(parent, name));
    }
    dispatch({ type: types.RESET_ROLES_CREATION });
  };
}

export function deleteRoles(name, parent = null) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_ROLES_REQUEST });
    try {
      const { data } = await deleteRolesByName(name, parent);
      if (data.status === 'OK') {
        dispatch({ type: types.DELETE_ROLES_SUCCEEDED, payload: { name, parent } });
      } else {
        dispatch({ type: types.DELETE_ROLES_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.DELETE_ROLES_FAILED, payload: err });
    }
  };
}

export function setDefaultRoleStatus(name) {
  return async (dispatch) => {
    dispatch({ type: types.SET_ROLE_DEFAULT_REQUEST });
    try {
      const { data } = await updateRoleDefaultStatus(name);
      if (data.status === 'OK') {
        dispatch({ type: types.SET_ROLE_DEFAULT_SUCCEEDED, payload: name });
      } else {
        dispatch({ type: types.SET_ROLE_DEFAULT_FAILED, payload: data.msg });
      }
    } catch (err) {
      console.error('[ERROR]: ', err);
      dispatch({ type: types.SET_ROLE_DEFAULT_FAILED, payload: err });
    }
  };
}

export function addExistingRolesAsChild({ parent, child }) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_CHILDS_REQUEST });
    try {
      const { data } = await addChildsToRoles(parent, child.map((i) => i.name));
      if (data.status === 'OK') {
        dispatch({ type: types.ADD_CHILDS_SUCCEEDED, payload: { parent, child } });
      } else {
        dispatch({ type: types.ADD_CHILDS_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.ADD_CHILDS_FAILED, payload: err });
    }
  };
}

export function resetAddChildRequest() {
  return (dispatch) => {
    dispatch({ type: types.ADD_CHILDS_RESET });
  };
}

import React, { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';

import styles from './ExpandablesRoles.module.css';
import Checkbox from './Checkbox';

export default function ExpandRole({
  role, selected = [], add, remove,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const getNumberOfChild = (parent) => {
    if (!parent.is_group) {
      return selected.includes(parent.name) ? 1 : 0;
    }
    return parent.child.reduce((acc, curr) => acc + getNumberOfChild(curr), 0);
  };

  const getAllRolesName = (parent) => {
    if (!parent.is_group) {
      return [parent.name];
    }
    return [
      parent.name,
      ...parent.child.reduce((acc, curr) => [...acc, ...getAllRolesName(curr)], []),
    ];
  };

  const handleChange = (bool, values) => {
    if (bool) {
      add(getAllRolesName(values), values);
    } else {
      remove(getAllRolesName(values), values);
    }
  };

  return (
    <div>
      {
          role.is_group
            ? (
              <div className={styles.group}>
                <div
                  role="button"
                  className={styles.button}
                  onClick={() => setIsOpen((state) => !state)}
                  onKeyDown={() => setIsOpen((state) => !state)}
                  tabIndex={-1}
                >
                  <div className={styles.row}>
                    <ExpandMore className={styles.icon} />
                    <p className={styles.title}>{`${role.name} (${role.child.length})`}</p>
                    <p className={styles.selected}>
                      {`${getNumberOfChild(role)} roles selected`}
                    </p>
                  </div>
                  <Checkbox
                    type="GROUP"
                    checked={selected.includes(role.name)}
                    selected={role.child.some((r) => selected.includes(r.name))}
                    onChange={(bool) => handleChange(bool, role)}
                  />
                </div>
                <ul className={styles.list}>
                  {
                    isOpen && role.child
                      .map((r) => (
                        <ExpandRole
                          key={r.name}
                          role={r}
                          selected={selected}
                          add={add}
                          remove={remove}
                        />
                      ))
                  }
                </ul>
              </div>
            )
            : (
              <div className={styles.role}>
                <p>
                  { role.name }
                </p>
                <Checkbox
                  type="ITEM"
                  checked={selected.includes(role.name)}
                  onChange={(bool) => handleChange(bool, role)}
                />
              </div>
            )
       }
    </div>
  );
}

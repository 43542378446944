import React, { useState, useEffect } from 'react';
import {
  AddCircle, BusinessCenter, Delete, Edit, Folder, RemoveCircle, SupervisedUserCircle, Visibility,
} from '@material-ui/icons';

import styles from './ExpandablesRoles.module.css';

export default function ExpandRole({
  role, selected, isChild, handleSelection, open,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const getIcon = (name) => {
    if (name.includes('GET')) return <Visibility className={styles.icon} />;
    if (name.includes('UPDATE')) return <Edit className={styles.icon} />;
    if (name.includes('DELETE')) return <Delete className={styles.icon} />;
    if (name.includes('ADMIN')) return <SupervisedUserCircle className={styles.icon} />;
    return <BusinessCenter className={styles.icon} />;
  };

  return (
    <div
      className={`${(role.name === selected) ? styles.selected : ''} ${isChild ? styles.child : ''}`}
    >
      {
          role.is_group
            ? (
              <div className={styles.group}>
                <div
                  role="button"
                  className={styles.button}
                  onClick={() => {
                    setIsOpen((state) => !state);
                    handleSelection(role.name);
                  }}
                  onKeyDown={() => setIsOpen((state) => !state)}
                  tabIndex={-1}
                >
                  <div className={styles.row}>
                    <Folder className={styles.icon} />
                    <p className={styles.title}>{`${role.name} (${role.child?.length})`}</p>
                  </div>
                  {
                    (isOpen)
                      ? <RemoveCircle className={styles.icon} />
                      : <AddCircle className={styles.icon} />
                  }
                </div>
                <ul className={styles.list}>
                  {
                    isOpen && role.child
                      .map((r) => (
                        <ExpandRole
                          key={r.name}
                          role={r}
                          selected={selected}
                          isChild
                          handleSelection={handleSelection}
                          open={open}
                        />
                      ))
                  }
                </ul>
              </div>
            )
            : (
              <div
                role="button"
                className={styles.role}
                onClick={() => {
                  handleSelection(role.name);
                }}
                onKeyDown={() => setIsOpen((state) => !state)}
                tabIndex={-1}
              >
                {
                  getIcon(role.name)
                }
                <p>
                  { role.name }
                </p>
              </div>
            )
       }
    </div>
  );
}

import React, { useState, useEffect } from 'react';

import styles from './Applications.module.css';
import shared from '../../styles/shared.module.css';
import { BRAND } from '../../global';

export default function Applications({ roles }) {
  const [applications, setApplications] = useState([]);

  useEffect(async () => {
    setApplications(roles);
  }, [roles]);

  return (
    <section className={styles.applications}>
      <p className={shared.logo}>{BRAND}</p>
      <ul className={styles.list}>
        {
          applications?.map((app) => (
            <button
              type="button"
              key={app.name}
              onClick={() => window.location.assign(app.link)}
            >
              { app.name }
            </button>
          ))
        }
      </ul>
    </section>
  );
}

import jwt from 'jsonwebtoken';
import { axiosDefaultClient as axios } from '../../../network/axiosClient';
import * as types from './types';
import { getStorage, setStorage } from '../../../../utils/saveLocal';

function verifyToken(callback) {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_ROLE_REQUEST });
    try {
      const { data } = await axios.get('/auth/cert');
      const { cert } = data.data;
      const token = getStorage('auth_token');
      jwt.verify(token, cert, async (err, decoded) => {
        if (!err) {
          dispatch({ type: types.FETCH_ROLE_SUCCEEDED, payload: decoded.roles });
          if (callback) {
            callback(getStorage('auth_refresh', false), token);
          }
        } else {
          const params = new URLSearchParams(window.location.search);
          let refreshToken = params.get('refresh_token');
          if (!refreshToken) {
            refreshToken = getStorage('auth_refresh', false);
          }
          if (refreshToken) {
            const { data: dataRefresh } = await axios.post('/auth/refresh_token', { refresh_token: refreshToken });
            if (dataRefresh.status === 'OK') {
              setStorage('auth_token', dataRefresh.data.token);
              setStorage('auth_refresh', dataRefresh.data.refresh_token, false);
              dispatch(verifyToken(callback));
            } else {
              document.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/`);
            }
          } else {
            document.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/`);
          }
        }
      });
    } catch (err) {
      console.error('[ERROR]: ', err);
      dispatch({ type: types.FETCH_ROLE_FAILED });
    }
  };
}

export function isUserAuthorized(usersRoles, necessaryRoles) {
  return necessaryRoles.every((r) => usersRoles.includes(r));
}

export default verifyToken;

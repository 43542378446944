import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/App.module.css';
import background from '../../background.svg';
import RouteConfig from '../../services/route/routes';
import ROUTES_USERS from '../../services/route/users';
import domains, { BRAND } from '../../global';

export default function UserAuth() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  return (
    <div className={`App ${styles.container}`}>
      <main className={styles.main}>
        <img
          src={background}
          alt="Description"
          className={styles.background}
        />
        <div className={styles.content}>
          <RouteConfig routes={ROUTES_USERS} />
        </div>
      </main>
      <footer className={styles.footer}>
        <nav className={styles.nav}>
          <a className={styles.logo} href={domains.ayomi}>
            {BRAND}
          </a>
          <a href={`${domains.ayomi}/solution`}>
            {t('solution')}
          </a>
          <a href={`${domains.ayomi}/legal`}>
            {t('legal')}
          </a>
        </nav>
        <select
          className={styles.selection}
          value={i18n.language}
          onChange={changeLanguage}
        >
          <option value="fr">Français</option>
          <option value="en">English</option>
        </select>
      </footer>
    </div>
  );
}

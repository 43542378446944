import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Error, Visibility, VisibilityOff } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import InputText from '../../../components/library/Input/InputText/InputText';
import styles from '../LogIn.module.css';
import Button from '../../../components/library/Button/Button';

export default function LogInForm({
  status, message, errors, values, handleChange, handleSubmit, connectAsUser, disableCreateAccount,
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showAsUser, setShowAsUser] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const asUser = params.get('asUser');
    if (asUser === 'true') {
      setShowAsUser(true);
    }
  }, []);

  const getButtonSubmitLabel = () => {
    if (status === 'LOADING') {
      return t('logIn.form.connectLoading');
    }
    if (status === 'SUCCESS') {
      return t('logIn.form.connectSuccess');
    }
    return t('logIn.form.connect');
  };

  const getButtonSubmitIcon = () => {
    if (status === 'LOADING') {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    if (status === 'SUCCESS') {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    }
    return null;
  };

  return (
    <form>
      {
        (status === 'ERROR')
        && (
        <p className={styles.error}>
          <Error className={styles.icon} />
          { message || t('logIn.form.error') }
        </p>
        )
      }
      <InputText
        label={t('logIn.form.mail')}
        placeholder={t('logIn.form.mail')}
        name="email"
        state={errors.email ? 'ERROR' : 'DEFAULT'}
        hint={errors.email}
        valueInput={values.email}
        onTextChange={handleChange}
      />
      <div>
        <div className={styles.row}>
          <p
            className={styles.label}
          >
            { t('logIn.form.password') }
          </p>
          <Button
            label={t('logIn.form.passwordForget')}
            variant="ghost"
            name="btn-forget"
            className={styles.custom}
            size="small"
            onButtonClick={() => history.push('/password')}
          />
        </div>
        <div className={`${styles.inputWrapper} ${errors.password ? styles.errorInput : ''}`}>
          {
            (isPasswordVisible)
              ? (
                <input
                  id="password"
                  placeholder={t('logIn.form.password')}
                  type="text"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              )
              : (
                <input
                  id="password"
                  placeholder={t('logIn.form.password')}
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              )
          }
          {
            (values.password)
            && (
              <button
                type="button"
                name="btn-show-password"
                className={styles.visibility}
                onClick={() => setIsPasswordVisible((state) => !state)}
              >
                {
                  (isPasswordVisible)
                    ? <VisibilityOff className={styles.icon} />
                    : <Visibility className={styles.icon} />
                }
              </button>
            )
          }
        </div>
        {
          (errors.password)
          && <p className={styles.hint}>{ errors.password }</p>
        }
      </div>
      {
        showAsUser
        && (
          <InputText
            label="Se connecter en tant que"
            name="emailAsUser"
            placeholder={t('logIn.form.mail')}
            onTextChange={handleChange}
            valueInput={values.emailAsUser || ''}
            state={errors.emailAsUser ? 'ERROR' : 'DEFAULT'}
            hint={errors.emailAsUser}
          />
        )
      }
      <div className={styles.buttonGroup}>
        <Button
          label={getButtonSubmitLabel()}
          icon={getButtonSubmitIcon()}
          name="submit"
          size="small"
          onButtonClick={showAsUser ? connectAsUser : handleSubmit}
        />
        {
          (!disableCreateAccount)
          && (
            <Button
              label={t('logIn.form.create')}
              name="btn-create-account"
              variant="ghost"
              size="small"
              onButtonClick={() => history.push((window.location.href.search(/[?]/g) !== -1) ? `/create-account?${window.location.href.split('?').pop()}` : '/create-account')}
            />
          )
        }
      </div>
    </form>
  );
}

LogInForm.defaultProps = {
  status: 'DEFAULT',
  values: {
    email: '',
    password: '',
  },
  errors: {},
  message: null,
};

LogInForm.propTypes = {
  status: Proptypes.oneOf(['DEFAULT', 'LOADING', 'SUCCESS', 'ERROR']),
  message: Proptypes.string,
  values: Proptypes.shape({
    email: Proptypes.string,
    password: Proptypes.string,
  }),
  errors: Proptypes.shape({
    email: Proptypes.string,
    password: Proptypes.string,
  }),
  handleChange: Proptypes.func.isRequired,
  handleSubmit: Proptypes.func.isRequired,
};

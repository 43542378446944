import React from 'react';
import styles from '../Debug.module.css';

export default function DebugDecodedToken({ decodedToken }) {
  return (
    <ul className={styles.list}>
      {
        decodedToken
        && Object.keys(decodedToken).map((key) => (
          <div key={key} className={styles.item}>
            <p className={styles.title}>
              { key }
            </p>
            {
              (key === 'roles')
                ? (
                  <div className={styles.table}>
                    {
                      decodedToken[key].map((i) => (
                        <p key={i}>
                          { i }
                        </p>
                      ))
                    }
                  </div>
                )
                : (
                  <p className={styles.value}>
                    {
                      decodedToken[key]
                    }
                  </p>
                )
            }
          </div>
        ))
      }
    </ul>
  );
}

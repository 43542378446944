import React, { useRef } from 'react';
import { CheckCircle } from '@material-ui/icons';
import styles from '../RoleForm.module.css';
import InputText from '../../../../../components/library/Input/InputText/InputText';
import Button from '../../../../../components/library/Button/Button';
import useOutsideClickAlert from '../../../../../components/library/hook/clickOutside';

export default function RoleFormMultiple({
  selected, creation, error, close, handleSubmit, name, setName,
}) {
  const modalRef = useRef();
  useOutsideClickAlert(modalRef, close);
  return (
    <div className={styles.overlay}>
      <section className={styles.popup} ref={modalRef}>
        <header className={styles.header}>
          <h3>
            Create multiple subroles
          </h3>
          {
                (selected !== 'NEW')
                && (
                  <p className={styles.create}>
                    {'Create multiple subroles for '}
                    <span>
                      {selected}
                    </span>
                  </p>
                )
             }
        </header>
        {
             (creation === 'ERROR' && error)
             && <p className={styles.error}>{`${error}`}</p>
          }
        {
             (creation === 'SUCCESS')
             && (
               <p className={styles.success}>
                 <CheckCircle className={styles.icon} />
                 Roles has been successfully created
               </p>
             )
          }
        <form className={styles.form}>
          <InputText
            label="Names (separate by ;)"
            valueInput={name}
            onTextChange={(e) => setName(e.target.value)}
          />
        </form>
        <footer className={styles.footer}>
          <Button
            label="Cancel"
            type="custom"
            className={styles.cancel}
            onButtonClick={close}
          />
          <Button
            label={`Create a new ${(selected === 'NEW') ? 'role' : 'subrole'}`}
            onButtonClick={handleSubmit}
          />
        </footer>
      </section>
    </div>
  );
}

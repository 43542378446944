export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';

export const FETCH_USER_ROLES_REQUEST = 'FETCH_USER_ROLES_REQUEST';
export const FETCH_USER_ROLES_FAILED = 'FETCH_USER_ROLES_FAILED';
export const FETCH_USER_ROLES_SUCCEEDED = 'FETCH_USER_ROLES_SUCCEEDED';

export const SET_SELECTED_USER = 'SET_SELECTED_USER';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const UPDATE_USER_SUCCEEDED = 'UPDATE_USER_SUCCEEDED';

export const VALIDATE_USER_REQUEST = 'VALIDATE_USER_REQUEST';
export const VALIDATE_USER_FAILED = 'VALIDATE_USER_FAILED';
export const VALIDATE_USER_SUCCEEDED = 'VALIDATE_USER_SUCCEEDED';

export const RESET_UPDATE = 'RESET_UPDATE';

export const ADD_USER_ROLES_REQUEST = 'ADD_USER_ROLES_REQUEST';
export const ADD_USER_ROLES_SUCCEEDED = 'ADD_USER_ROLES_SUCCEEDED';
export const ADD_USER_ROLES_FAILED = 'ADD_USER_ROLES_FAILED';

export const REMOVE_USER_ROLES_REQUEST = 'REMOVE_USER_ROLES_REQUEST';
export const REMOVE_USER_ROLES_SUCCEEDED = 'REMOVE_USER_ROLES_SUCCEEDED';
export const REMOVE_USER_ROLES_FAILED = 'REMOVE_USER_ROLES_FAILED';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCEEDED = 'DELETE_USER_SUCCEEDED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

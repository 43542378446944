import { connect } from 'react-redux';

import Admin from './Admin';

const mapStateToProps = (state) => ({
  selectedUser: state.users.selectedUser,
  selectedRole: state.admin.selectedRole,
  roles: state.roles.roles,
});

export default connect(mapStateToProps)(Admin);

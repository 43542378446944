import React from 'react';

import styles from './SuccessAnimation.module.css';

export default function SuccessAnimation() {
  return (
    <div className={styles.successCheckmark}>
      <div className={styles.checkIcon}>
        <span className={`${styles.iconLine} ${styles.lineTip}`} />
        <span className={`${styles.iconLine} ${styles.lineLong}`} />
        <div className={styles.iconCircle} />
        <div className={styles.iconFix} />
      </div>
    </div>
  );
}

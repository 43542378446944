import * as types from './types';
import {
  getRolesByUser, getUsersList, updateUserByEmail,
  addRolesToUser, deleteRolesToUser, validateAccount, deleteAccount,
} from '../../../network/authentication';

export function fetchUsers() {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_USERS_REQUEST });
    try {
      const { data } = await getUsersList();
      if (data.status === 'OK') {
        dispatch({ type: types.FETCH_USERS_SUCCEEDED, payload: data.data });
      } else {
        dispatch({ type: types.FETCH_USERS_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.FETCH_USERS_SUCCEEDED, payload: err });
    }
  };
}

export function fetchUserRoles(email) {
  return async (dispatch) => {
    dispatch({ type: types.FETCH_USER_ROLES_REQUEST });
    try {
      const { data } = await getRolesByUser(email);
      dispatch({
        type: types.FETCH_USER_ROLES_SUCCEEDED,
        payload: { email, roles: data.data.roles },
      });
    } catch (err) {
      dispatch({ type: types.FETCH_USER_ROLES_FAILED, payload: err });
    }
  };
}

export function setSelectedUser(email) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_USER, payload: email });
  };
}

export function updateSelectedUser(values) {
  return async (dispatch, getState) => {
    dispatch({ type: types.UPDATE_USER_REQUEST });
    const { selectedUser } = getState().users;
    try {
      const { data } = await updateUserByEmail(selectedUser, values);
      if (data.status === 'OK') {
        dispatch({ type: types.UPDATE_USER_SUCCEEDED, payload: values });
      } else {
        dispatch({ type: types.UPDATE_USER_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.UPDATE_USER_FAILED, payload: err });
    }
  };
}

export function validateSelectedUserAccount() {
  return async (dispatch, getState) => {
    dispatch({ type: types.VALIDATE_USER_REQUEST });
    const { selectedUser } = getState().users;
    try {
      const { data } = await validateAccount(selectedUser);
      if (data.status === 'OK') {
        dispatch({ type: types.VALIDATE_USER_SUCCEEDED });
      } else {
        dispatch({ type: types.VALIDATE_USER_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.VALIDATE_USER_FAILED, payload: err });
    }
  };
}

export function deleteSelectedUserAccount() {
  return async (dispatch, getState) => {
    dispatch({ type: types.DELETE_USER_REQUEST });
    const { selectedUser } = getState().users;
    try {
      const { data } = await deleteAccount(selectedUser);
      if (data.status === 'OK') {
        dispatch({ type: types.DELETE_USER_SUCCEEDED, payload: selectedUser });
      } else {
        dispatch({ type: types.DELETE_USER_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.DELETE_USER_FAILED, payload: err });
    }
  };
}

export function resetUpdate() {
  return async (dispatch) => {
    dispatch({ type: types.RESET_UPDATE });
  };
}

export function addUserRoles(roles, parent) {
  return async (dispatch, getState) => {
    dispatch({ type: types.ADD_USER_ROLES_REQUEST });
    const email = getState().users.selectedUser;
    try {
      const { data } = await addRolesToUser(email, [parent.name]);
      if (data.status === 'OK') {
        dispatch({ type: types.ADD_USER_ROLES_SUCCEEDED, payload: { email, roles } });
      } else {
        dispatch({ type: types.ADD_USER_ROLES_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.ADD_USER_ROLES_FAILED, payload: err });
    }
  };
}

export function removeUserRoles(roles, parent) {
  return async (dispatch, getState) => {
    dispatch({ type: types.REMOVE_USER_ROLES_REQUEST });
    const email = getState().users.selectedUser;
    try {
      const { data } = await deleteRolesToUser(email, [parent.name]);
      if (data.status === 'OK') {
        dispatch({ type: types.REMOVE_USER_ROLES_SUCCEEDED, payload: { email, roles } });
      } else {
        dispatch({ type: types.REMOVE_USER_ROLES_FAILED, payload: data.msg });
      }
    } catch (err) {
      dispatch({ type: types.REMOVE_USER_ROLES_FAILED, payload: err });
    }
  };
}

export default fetchUsers;

import React, { useState } from 'react';

import Information from './Information';
import Roles from './Roles';

export default function UserDescription({ selectedUser, isEditable }) {
  const [status, setStatus] = useState('INFORMATIONS');
  if (status === 'ROLES') {
    return <Roles setStatus={setStatus} selectedUser={selectedUser} isEditable={isEditable} />;
  }
  return (
    <Information
      key={selectedUser?.id}
      setStatus={setStatus}
      selectedUser={selectedUser}
      isEditable={isEditable}
    />
  );
}

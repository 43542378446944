const AUTH_PROD = `https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'ayomi.fr'}/compte/redirect/prod/`;
const DASHBOARD = `https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}dashboard.ayomi.fr/`;

const redirect = (data, timeout = 1000) => {
  window.setTimeout(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect');
    if (redirectUrl && data.data?.refresh_token) {
      const url = new URL(AUTH_PROD);
      const redirection = new URL(redirectUrl);
      redirection.searchParams.delete('refresh_token');
      if (process.env.REACT_APP_DEBUG) {
        if (redirection.href) {
          window.location.assign(`${redirection.href}?refresh_token=${data.data?.refresh_token}`);
        } else {
          window.location.assign(`${DASHBOARD}?refresh_token=${data.data?.refresh_token}`);
        }
      } else {
        url.searchParams.append('refresh_token', data.data?.refresh_token);
        url.searchParams.append('redirect', redirection.href);
        window.location.assign(url);
      }
    } else if (data.data?.refresh_token) {
      if (process.env.REACT_APP_DEBUG) {
        window.location.assign(`${DASHBOARD}?refresh_token=${data.data?.refresh_token}`);
      } else {
        window.location.assign(`${AUTH_PROD}?redirect=${DASHBOARD}&refresh_token=${data.data?.refresh_token}`);
      }
    } else if (process.env.REACT_APP_DEBUG) {
      window.location.assign(DASHBOARD);
    } else {
      window.location.assign(`${AUTH_PROD}?redirect=${DASHBOARD}`);
    }
  }, timeout);
};

export default redirect;

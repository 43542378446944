import React from 'react';
import CopyPopUp from '../../../../../../components/CopyPopUp';

export default function LogInPopUp({
  status, close, error, url,
}) {
  return (
    <CopyPopUp
      status={status}
      close={close}
      error={error}
      loading="Login as user ongoing ..."
      success="Login as user succeeded"
      value={url}
    />
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';

import verifyToken from '../store/features/roles/actions';
import Loading from '../../components/library/Loading';

export default function RouteConfig({ routes }) {
  return (
    <Router>
      <Switch>
        {routes && routes.map((route) => (
          <ConnectedRouteWithSubRoutes key={route.path} {...route} />
        ))}
      </Switch>
    </Router>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes({
  path, auth, shouldRedirect, routes, status, roles, authorizedRole, ...route
}) {
  const [showRoute, setShowRoute] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get('debug');
    if (debug === 'true') {
      history.push('/debug');
    }
    if (auth) {
      dispatch(verifyToken());
    }
  }, []);

  useEffect(() => {
    if (roles && roles.length > 0 && status === 'SUCCEEDED') {
      // eslint-disable-next-line no-restricted-syntax
      for (const role of roles) {
        if (!authorizedRole.includes(role)) {
          if (history.length === 1) {
            document.location.assign(`https//${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr/`);
          } else {
            history.goBack();
          }
        } else {
          setShowRoute(true);
        }
      }
    } else {
      setShowRoute(true);
    }
  }, [status]);

  if (auth && !showRoute) {
    return <Loading />;
  }
  return (
    <Route
      path={path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={routes} />
      )}
    />
  );
}

const mapStateToProps = (state) => ({
  authorizedRole: state.roles.roles,
  status: state.roles.status,
});

const ConnectedRouteWithSubRoutes = connect(mapStateToProps)(RouteWithSubRoutes);

import React from 'react';
import PropTypes from 'prop-types';

import styles from './UnlockAnimation.module.css';
import '../style/animation.css';

export default function UnlockAnimation({ status }) {
  return (
    <div className={styles.container}>
      <span className={`bounceIn ${styles.lock} ${styles[status]}`} />
    </div>
  );
}

UnlockAnimation.defaultProps = {
  status: 'lock',
};

UnlockAnimation.propTypes = {
  status: PropTypes.oneOf(['lock', 'unlocked']),
};

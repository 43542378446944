import React, { useEffect, useState } from 'react';

import RoleFormChild from './RoleFormChild';
import RoleFormMultiple from './RoleFormMultiple';
import RoleFormSingle from './RoleFormSingle';

export default function RoleForm({
  status, selected, close, error, creation, type = 'SINGLE', actions,
}) {
  const [name, setName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [hasSubmit, setHasSubmit] = useState(false);

  const handleSubmit = () => {
    setHasSubmit(true);
    const arrayOfName = name.split(';');
    const parent = (selected !== 'NEW') ? selected : null;
    if (!parent) {
      actions.createNewRolesByName({ name, default: isDefault });
    } else {
      actions.createNewRoleAndAddChild(arrayOfName, parent);
    }
  };

  useEffect(() => {
    actions.resetRoleCreation();
    if (status === 'IDLE') {
      actions.fetchAllRoles();
    }
  }, []);

  useEffect(() => {
    if (creation === 'IDLE' && hasSubmit) {
      close();
    }
  }, [creation]);

  if (type === 'MULTIPLE') {
    return (
      <RoleFormMultiple
        selected={selected}
        creation={creation}
        error={error}
        close={close}
        handleSubmit={handleSubmit}
        name={name}
        setName={setName}
      />
    );
  }

  if (type === 'CHILD') {
    return (
      <RoleFormChild close={close} selected={selected} />
    );
  }

  return (
    <RoleFormSingle
      selected={selected}
      creation={creation}
      error={error}
      close={close}
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      isDefault={isDefault}
      setIsDefault={setIsDefault}
    />
  );
}

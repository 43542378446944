import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Personal from './Personal';
import { updateSelectedUser } from '../../../../../../services/store/features/users/actions';

const mapStateToProps = (state) => ({
  status: state.users.update,
  error: state.users.error,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    updateSelectedUser,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Personal);

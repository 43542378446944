import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import stylesInput from '../shared/Input.module.css';
import styles from './InputText.module.css';

export default function InputText({
  label, placeholder, hint, icon,
  type,
  name,
  disabled, state,
  valueInput, onTextChange,
  className,
  required,
  onBlur,
  onKeyDown,
  ref,
}) {
  const getContainerClass = () => {
    if (disabled) {
      return `${styles.disabled} ${className}`;
    }
    if (state === 'SUCCESS') {
      return `${styles.success} ${className}`;
    }
    if (state === 'ERROR') {
      return `${styles.container} ${className}`;
    }
    return `${styles.container} ${className}`;
  };

  const getInputClass = () => {
    if (disabled) {
      return `${stylesInput.input} ${styles.inputDisabled}`;
    }
    if (state === 'SUCCESS') {
      return `${stylesInput.input} ${styles.inputSuccess}`;
    }
    if (state === 'ERROR') {
      return `${stylesInput.input} ${styles.inputError}`;
    }
    return stylesInput.input;
  };

  const getHintClass = () => {
    if (disabled) {
      return `${styles.hint} ${styles.hintDisabled}`;
    }
    if (state === 'SUCCESS') {
      return `${styles.hint} ${styles.hintSuccess}`;
    }
    if (state === 'ERROR') {
      return `${styles.hint} ${styles.hintError}`;
    }
    return styles.hint;
  };

  return (
    <div className={getContainerClass()}>
      { (label && label !== '')
        && (
          <p
            className={styles.label}
          >
            {(required) && (<span className={styles.required}> * </span>)}
            { label }
          </p>
        )}
      <div className={getInputClass()}>
        <input
          ref={ref}
          type={(type === 'number') ? 'number' : 'text'}
          placeholder={placeholder}
          value={valueInput}
          onChange={onTextChange}
          name={(name) || undefined}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          aria-label={name}
          readOnly={disabled}
        />
        {(state === 'SUCCESS') && <FontAwesomeIcon className={styles.iconSuccess} icon={faCheck} />}
        {(state === 'ERROR') && <FontAwesomeIcon className={styles.iconError} icon={faTimes} />}
        {(icon)
        && (
          <div className={styles.iconContainer}>
            { icon }
          </div>
        )}
      </div>
      {
        (state === 'ERROR')
          ? (
            <p
              role="alert"
              className={getHintClass()}
            >
              {hint}
            </p>
          )
          : hint && (
            <p
              className={getHintClass()}
            >
              {hint}
            </p>
          )
      }
    </div>
  );
}

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye, faEyeSlash, faCheck, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import styles from './PasswordInput.module.css';
import stylesInput from '../shared/Input.module.css';

export default function PasswordInput({
  label = 'Mot de passe',
  name,
  lastName = undefined,
  firstName = undefined,
  email = undefined,
  required = false,
  password = '',
  state = 'DEFAULT',
  hint = undefined,
  onChange,
  showPasswordHint = false,
  visible = false,
  className,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showRequierement, setShowRequierement] = useState(false);

  const { t } = useTranslation();

  const calculatePasswordStrength = () => {
    let result = 0;
    if (password.length > 8) result += 50;
    const hasUppercase = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
    if (hasUppercase.test(password)) {
      const bonus = (password.match(/[A-Z]/g).length < 3 ? password.match(/[A-Z]/g).length : 3);
      result += 10 * (bonus);
    }
    const hasSpecialCharacter = new RegExp('^(?=.*[!@#[$%}^";&_\'{*])');
    if (password.match(hasSpecialCharacter)) {
      const bonus = (password.match(/[!@#[$%}^";&_'{*]/g).length < 3 ? password.match(/[!@#$%^"';|[_&*]/g).length : 3);
      result += 10 * bonus;
    }
    const hasNumber = new RegExp('^(?=.*(1-9))');
    if (password.match(hasNumber)) {
      result += 10;
    }
    if (password.length > 10) result += password.length;
    return result;
  };
  const getPasswordStrength = () => {
    const result = calculatePasswordStrength();
    if (result < 50) {
      return t('password.weak');
    }
    if (result < 70) {
      return t('password.ok');
    }
    if (result < 100) return t('password.good');
    return t('password.perfect');
  };

  const isPasswordLongEnough = () => password.length > 8;

  const hasPasswordBanWords = () => {
    if (lastName) {
      if (password.includes(lastName)) return true;
    }
    if (firstName) {
      if (password.includes(firstName)) return true;
    }
    if (email) {
      if (password.includes(email)) return true;
    }
    return false;
  };

  return (
    <div>
      <div className={styles.row}>
        <p
          className={`${stylesInput.label} ${className}`}
        >
          {(required) && (<span className={styles.required}> * </span>)}
          { label }
        </p>
        <button
          type="button"
          className={styles.showPassword}
          onClick={() => setShowPassword(!showPassword)}
          hidden={!visible}
        >
          {
            (!showPassword)
              ? (<FontAwesomeIcon className={styles.icon} icon={faEye} />)
              : (<FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />)
          }
          {(!showPassword) ? 'show' : 'hide'}
        </button>
      </div>
      <div className={`${stylesInput.input} ${(state === 'ERROR') ? styles.errorInput : ''}`}>
        <input
          name="password"
          className={styles.input}
          type={(!showPassword) ? 'password' : 'text'}
          value={password}
          aria-label={name}
          onChange={(e) => {
            onChange(e);
            setShowRequierement(true);
          }}
          onBlur={() => setShowRequierement(false)}
        />
      </div>
      {
        (hint) && <p className={styles.errorHint}>{ hint }</p>
      }
      {
        (showPasswordHint)
        && (
          <div className={styles.passwordHint} hidden={!showRequierement}>
            {
              (calculatePasswordStrength() > 70)
                ? (
                  <p className={styles.valid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faCheck} />
                    {t('password.security')}
                    {' '}
                    {getPasswordStrength()}
                  </p>
                )
                : (
                  <p className={styles.invalid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faTimes} />
                    {t('password.security')}
                    {' '}
                    {getPasswordStrength()}
                  </p>
                )
            }
            {
              (isPasswordLongEnough())
                ? (
                  <p className={styles.valid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faCheck} />
                    {t('password.minimum')}
                  </p>
                )
                : (
                  <p className={styles.invalid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faTimes} />
                    {t('password.minimum')}
                  </p>
                )
            }
            {
              (!hasPasswordBanWords())
                ? (
                  <p className={styles.valid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faCheck} />
                    {t('password.easyToFind')}
                  </p>
                )
                : (
                  <p className={styles.invalid}>
                    <FontAwesomeIcon className={styles.hintIcon} icon={faTimes} />
                    {t('password.easyToFind')}
                  </p>
                )
            }
          </div>
        )
      }
    </div>
  );
}

import React from 'react';
import RouteConfig from './services/route/routes';
import ROUTES from './services/route/base';

function App() {
  return (
    <RouteConfig routes={ROUTES} />
  );
}

export default App;

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Information from './Information';
import { fetchUserRoles, resetUpdate } from '../../../../../services/store/features/users/actions';

const mapStateToProps = (state) => ({
  roles: state.roles.roles,
  userRoles: state.users.users.find((user) => user.email === state.users.selectedUser).roles,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    resetUpdate,
    fetchUserRoles,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './Checkbox.module.css';

export default function Checkbox({
  label = '',
  onChange = null,
  disabled = false,
  checked = false,
  className = '',
  children,
}) {
  const handleChange = () => {
    const newValue = !checked;
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <button
        type="button"
        className={
          (disabled) ? `${styles.checkbox} ${styles.disabled}` : (`${styles.checkbox} ${(checked) ? (styles.checked) : (styles.unchecked)}`)
        }
        onClick={handleChange}
      >
        {(checked) && (!disabled) && (
          <FontAwesomeIcon
            className={styles.icon}
            icon={faCheck}
          />
        )}
      </button>
      {
        (label && label.trim() !== '')
          ? (
            <p className={`${styles.label} ${(disabled) ? styles.labelDisabled : ''}`}>
              {label}
            </p>
          )
          : (
            <div>
              { children }
            </div>
          )
      }
    </div>
  );
}

import React, { useEffect } from 'react';
import Loading from '../../components/library/Loading';

export default function Disconnect() {
  const handleLogout = () => {
    window.localStorage.removeItem('auth_refresh');
    window.sessionStorage.removeItem('auth_token');
    window.location.assign('/');
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <Loading />;
}

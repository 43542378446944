import React from 'react';
import styles from '../../UserDescription.module.css';
import SecurityPassword from './SecurityPassword';
import SecurityAccount from './SecurityAccount';

export default function Security({ roles, user, isEditable }) {
  return (
    <section className={styles.section}>
      <header>
        <h2>
          Security
        </h2>
      </header>
      {
        (isEditable)
        && (
          <SecurityPassword
            roles={roles}
            email={user.email}
          />
        )
      }
      <SecurityAccount
        isEditable={isEditable}
      />

    </section>
  );
}

export const setStorage = (name, value, session = true) => {
  try {
    if (session) {
      window.sessionStorage.setItem(name, value);
    } else {
      window.localStorage.setItem(name, value);
    }
  } catch (err) {
    console.error('User seems to be in private mode', err);
  }
};

export const getStorage = (name, session = true) => {
  let findValue;
  try {
    if (session) {
      findValue = window.sessionStorage.getItem(name);
    } else {
      findValue = window.localStorage.getItem(name);
    }
    return findValue;
  } catch (err) {
    console.error('User seems to be in private mode', err);
    return undefined;
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import { MailOutline } from '@material-ui/icons';
import Button from '../../components/library/Button/Button';
import shared from '../../styles/shared.module.css';
import styles from './ForceReset.module.css';
import { BRAND } from '../../global';

export default function ForceReset({ reset }) {
  const { t } = useTranslation();
  return (
    <section className={styles.section}>
      <p className={shared.logo}>
        {BRAND}
      </p>
      <div className={styles.illustration}>
        <MailOutline className={styles.icon} />
      </div>
      <h1>{t('reset.force')}</h1>
      <p className={styles.description}>
        {t('reset.description')}
      </p>
      <Button
        label={t('reset.back')}
        onButtonClick={reset}
      />
    </section>
  );
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import shared from '../../styles/shared.module.css';
import styles from './Confirm.module.css';
import SuccessAnimation from '../../components/library/SuccessAnimation/SuccessAnimation';
import { confirmAccount } from '../../services/network/authentication';
import Loading from '../../components/library/Loading';
import ErrorAnimation from '../../components/library/ErrorAnimation/ErrorAnimation';
import { BRAND } from '../../global';

export default function Confirm() {
  const [state, setState] = useState('IDLE');
  const { t } = useTranslation();

  useEffect(async () => {
    const fetchConfirmation = async () => {
      setState('LOADING');
      try {
        const params = new URLSearchParams(window.location.search);
        const email = params.get('email');
        const { data } = await confirmAccount(email);
        if (data.status === 'OK') {
          setState('SUCCESS');
        } else {
          setState('ERROR');
        }
      } catch (err) {
        setState('ERROR');
      }
    };

    await fetchConfirmation();
  }, []);

  if (state === 'SUCCESS') {
    return (
      <section className={styles.modal}>
        <p className={shared.logo}>
          {BRAND}
        </p>
        <div className={styles.success}>
          <SuccessAnimation />
        </div>
        <h1>
          {t('account.confirm.title')}
        </h1>
        <p className={styles.description}>
          {t('account.confirm.description')}
        </p>
        <Link
          to="/"
          className={styles.button}
        >
          {t('account.confirm.login')}
        </Link>
      </section>
    );
  }

  if (state === 'ERROR') {
    return (
      <section className={styles.modal}>
        <p className={shared.logo}>
          {BRAND}
        </p>
        <ErrorAnimation />
        <h1>
          {t('account.confirm.error.title')}
        </h1>
        <p className={styles.description}>
          {t('account.confirm.error.description')}
        </p>
        <a
          href={`https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'ayomi.fr'}`}
          className={styles.button}
        >
          {t('account.confirm.error.create')}
        </a>
      </section>
    );
  }

  return (
    <section className={styles.modal}>
      <p className={shared.logo}>
        {BRAND}
      </p>
      <div className={styles.success}>
        <Loading />
      </div>
    </section>
  );
}

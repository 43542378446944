import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UsersList from './UsersList';
import { fetchUsers, setSelectedUser } from '../../../../services/store/features/users/actions';

const mapStateToProps = (state) => ({
  status: state.users.status,
  users: state.users.users,
  selectedUser: state.users.selectedUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchUsers, setSelectedUser }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);

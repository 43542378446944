import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Done, Error } from '@material-ui/icons';
import UnlockAnimation from '../../../components/library/UnlockAnimation';
import InputText from '../../../components/library/Input/InputText/InputText';
import Button from '../../../components/library/Button/Button';
import redirect from '../../../utils/redirect';

import styles from './DoubleAuth.module.css';
import shared from '../LogIn.module.css';
import { getUserToken, logIn } from '../../../services/network/authentication';
import { BRAND } from '../../../global';

export default function DoubleAuth({ email, as }) {
  const [status, setStatus] = useState('IDLE');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');

  const { t } = useTranslation();

  const handleSubmit = async () => {
    setStatus('LOADING');
    try {
      const { data } = await logIn({ email, code });
      if (data.status !== 'KO') {
        setStatus('SUCCESS');
        if (data.data.refresh_token && data.data.token) {
          window.sessionStorage.setItem('auth_token', data.data.token);
          window.localStorage.setItem('auth_refresh', data.data.refresh_token);
        }
        if (as && as.trim() !== '') {
          const { data: dataAsUser } = await getUserToken(as);
          if (dataAsUser.status === 'KO') {
            setStatus('ERROR');
            setMessage(t('logIn.errors.connectAsUserNotAuthorize'));
            window.setTimeout(() => window.location.assign(`https://${process.env.REACT_APP_DEBUG ? 'dev-' : ''}auth.ayomi.fr`), 4000);
          } else {
            window.sessionStorage.setItem('auth_token', dataAsUser.data.token);
            window.localStorage.setItem('auth_refresh', dataAsUser.data.refresh_token);
            redirect(dataAsUser);
          }
        } else {
          redirect(data);
        }
      }
    } catch (err) {
      setStatus('ERROR');
    }
  };

  return (
    <section className={shared.logIn}>
      <p className={shared.logo}>{BRAND}</p>
      {
        (status === 'LOADING')
          ? (
            <div className={styles.loading}>
              <FontAwesomeIcon
                className={styles.icon}
                icon={faSpinner}
                spin
              />
            </div>
          )
          : (
            <div className={(status === 'SUCCESS') ? styles.unlock : styles.lock}>
              <UnlockAnimation
                status={(status === 'SUCCESS') ? 'unlocked' : 'lock'}
              />
            </div>
          )
      }
      <p className={styles.title}>
        {t('doubleAuth.title')}
      </p>
      <p className={styles.description}>
        {t('doubleAuth.confirm')}
      </p>
      {
        (status === 'ERROR' && !message)
        && (
          <p className={shared.error}>
            <Error className={shared.icon} />
            { t('logIn.form.error') }
          </p>
        )
      }
      {
        (status === 'ERROR' && message)
        && (
          <p className={shared.error}>
            <Error className={shared.icon} />
            { message }
          </p>
        )
      }
      <InputText
        label={t('doubleAuth.input')}
        valueInput={code}
        onTextChange={(e) => setCode(e.target.value)}
      />
      <Button
        label={(status === 'SUCCESS') ? t('doubleAuth.accountVerified') : t('doubleAuth.submit')}
        className={styles.button}
        onButtonClick={handleSubmit}
        icon={(status === 'SUCCESS') && <Done />}
        disabled={(code === '' || code.trim() === '')}
      />
    </section>
  );
}

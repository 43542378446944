import React from 'react';

import { ExitToApp } from '@material-ui/icons';
import Button from '../../../components/library/Button/Button';
import styles from './Navigation.module.css';
import shared from '../../../styles/shared.module.css';
import { isUserAuthorized } from '../../../services/store/features/roles/actions';

export default function Navigation({ navigation, roles, setNavigation }) {
  const handleLogout = () => {
    window.localStorage.removeItem('auth_refresh');
    window.sessionStorage.removeItem('auth_token');
    window.location.assign('/');
  };

  return (
    <nav className={styles.navigation}>
      <p className={shared.logo}>
        AYOMI
      </p>
      {
        (isUserAuthorized(roles, ['AUTH_USERS_GET']))
        && (
          <Button
            label="Users"
            variant="custom"
            className={(navigation === 'USERS') ? styles.active : ''}
            onButtonClick={() => setNavigation('USERS')}
          />
        )
      }
      {
        (isUserAuthorized(roles, ['AUTH_ROLES_GET_ALL'])
        && (
        <Button
          label="Roles"
          variant="custom"
          className={(navigation === 'ROLES') ? styles.active : ''}
          onButtonClick={() => setNavigation('ROLES')}
        />
        ))
      }
      <Button
        label="Se déconnecter"
        variant="custom"
        className={styles.logOut}
        icon={<ExitToApp />}
        onButtonClick={handleLogout}
      />
    </nav>
  );
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AccountForm from './AccountForm';
import styles from './Account.module.css';
import shared from '../../styles/shared.module.css';
import validateMail from '../../utils/validateMail';
import { createAccount, logIn } from '../../services/network/authentication';
import redirect from '../../utils/redirect';
import { BRAND } from '../../global';

export default function Account() {
  const [status, setStatus] = useState('IDLE');
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const firstname = params.get('firstname');
    const lastname = params.get('lastname');
    const email = params.get('email');
    const phone = params.get('phone');
    setValues({
      firstname, lastname, email, phone,
    });
  }, []);

  const handleChange = (e) => {
    setValues((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!values.firstname) {
      newErrors.firstname = (t('account.errors.firstnameEmpty'));
    }
    if (!values.lastname) {
      newErrors.lastname = (t('account.errors.lastnameEmpty'));
    }
    if (!values.email) {
      newErrors.email = (t('account.errors.mailEmpty'));
    }
    if (values.email && !validateMail(values.email.trim())) {
      newErrors.email = (t('account.errors.mailInvalid'));
    }
    if (!values.phone) {
      newErrors.phone = (t('account.errors.phoneEmpty'));
    }
    if (!values.password) {
      newErrors.password = (t('account.errors.passwordEmpty'));
    }
    if (!values.sales || values.sales === false) {
      newErrors.sales = t('account.errors.sales');
    }
    return newErrors;
  };

  const handleLogIn = async () => {
    const { data: login } = await logIn({
      email: values.email,
      password: values.password.trim(),
    });
    if (login.status !== 'KO') {
      window.localStorage.setItem('auth_refresh', login.data.refresh_token);
      window.sessionStorage.setItem('auth_token', login.data.token);
      redirect(login);
    } else {
      setStatus('ERROR');
      setErrors({ server: login.message });
    }
  };

  const handleSubmit = async () => {
    const newErrors = validate();
    if (Object.values(newErrors).length === 0) {
      try {
        setStatus('LOADING');
        const params = new URLSearchParams(window.location.search);
        const autoLogin = params.get('auto_login');
        const redirection = params.get('redirect');
        if (autoLogin === '1' || autoLogin === '2') {
          values.validate = '1';
        }
        if (redirection) {
          values.redirect = redirection;
        }
        const { data } = await createAccount({ ...values, email: values.email.trim() });
        if (data.status === 'OK' && (autoLogin === '1' || autoLogin === '2')) {
          window.setTimeout(handleLogIn, 10000);
        } else if (data.status === 'OK') {
          setStatus('SUCCESS');
        } else {
          setStatus('ERROR');
          setErrors({ server: data.msg });
        }
      } catch (err) {
        setStatus('ERROR');
        if (err.response?.status === 409) {
          setErrors({ server: t('account.errors.userExist') });
        } else {
          setErrors({ server: t('account.errors.technical') });
        }
      }
    } else {
      setErrors(newErrors);
    }
  };

  if (status === 'SUCCESS') {
    return (
      <section className={`${styles.success} ${styles.account}`}>
        <p className={shared.logo}>{BRAND}</p>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={styles.checkIcon}
        />
        <h1 className={styles.title}>
          {t('account.creation.title')}
        </h1>
        <p className={styles.description}>
          {t('account.creation.description')}
        </p>
      </section>
    );
  }

  return (
    <section className={styles.account}>
      <p className={shared.logo}>
        {BRAND}
      </p>
      <AccountForm
        status={status}
        values={values}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </section>
  );
}

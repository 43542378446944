import { combineReducers } from 'redux';
import rolesReducer from './features/roles/reducer';
import usersReducer from './features/users/reducer';
import adminReducer from './features/admin/reducer';

const rootReducer = combineReducers({
  roles: rolesReducer,
  users: usersReducer,
  admin: adminReducer,
});

export default rootReducer;

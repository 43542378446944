import React from 'react';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { faExclamationCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputText from '../../../components/library/Input/InputText/InputText';
import Checkbox from '../../../components/library/Input/Checkbox/Checkbox';
import Button from '../../../components/library/Button/Button';
import PasswordInput from '../../../components/library/Input/PasswordInput/PasswordInput';
import InputPhone from '../../../components/library/Input/InputPhone/InputPhone';

import styles from './AccountForm.module.css';

export default function AccountForm({
  status, values, errors, handleChange, handleSubmit,
}) {
  const { t, i18n } = useTranslation();
  return (
    <form>
      {
        (errors.server)
        && (
        <p className={styles.errorServer}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </span>
          {
            (errors.server === 'Email already exists !')
              ? t('account.errors.userExist')
              : errors.server
          }
          { (errors.server === 'Email already exists !') && (
            <Link
              to={`/?email=${values.email}`}
              className={styles.link}
            >
              {t('account.form.alreadyExist')}
            </Link>
          )}
        </p>
        )
      }
      <InputText
        label={t('account.form.firstname')}
        name="firstname"
        valueInput={values.firstname || ''}
        hint={errors.firstname}
        state={errors.firstname ? 'ERROR' : 'DEFAULT'}
        onTextChange={handleChange}
      />
      <InputText
        label={t('account.form.lastname')}
        name="lastname"
        valueInput={values.lastname || ''}
        hint={errors.lastname}
        state={errors.lastname ? 'ERROR' : 'DEFAULT'}
        onTextChange={handleChange}
      />
      <InputText
        label={t('account.form.mail')}
        name="email"
        valueInput={values.email || ''}
        hint={errors.email}
        state={errors.email ? 'ERROR' : 'DEFAULT'}
        onTextChange={handleChange}
        disabled={new URLSearchParams(window.location.search).get('auto_login') === '1'}
      />
      <InputPhone
        label={t('account.form.phone')}
        name="phone"
        phone={values.phone || ''}
        setPhone={(value) => handleChange(
          {
            target: {
              name: 'phone',
              value,
            },
          },
        )}
        state={errors.phone ? 'ERROR' : 'DEFAULT'}
        msg={errors.phone}
      />
      <PasswordInput
        label={t('account.form.password')}
        name="password"
        password={values.password}
        firstName={values.firstname}
        lastName={values.lastname}
        email={values.email}
        hint={errors.password}
        state={errors.password ? 'ERROR' : 'DEFAULT'}
        onChange={(e) => {
          e.target.value = e.target.value.trim();
          handleChange(e);
        }}
        showPasswordHint
        visible
      />
      <Checkbox
        label={t('account.form.newsletter')}
        name="newsletter"
        className={styles.checkboxContainer}
        checked={values.newsletter}
        onChange={(bool) => handleChange({ target: { name: 'newsletter', value: bool } })}
      />
      <Checkbox
        className={styles.checkboxContainer}
        name="sales"
        checked={values.sales}
        onChange={(bool) => handleChange({ target: { name: 'sales', value: bool } })}
      >
        <span className={styles.checkbox}>
          {t('account.form.accept')}
          {' '}
          <a
            href={(i18n.language === 'en') ? `https://${process.env.REACT_APP_DEBUG ? 'dev.ipoome.com' : 'ayomi.fr'}/cy/documents/` : 'https://media.ayomi.fr/media/public/ayomi/CGV.pdf'}
            target="_blank"
            rel="noreferrer"
            className={styles.linkSales}
          >
            {' '}
            {t('account.form.sales')}
          </a>
        </span>
      </Checkbox>
      {
        (errors.sales)
        && <p className={styles.hintError}>{ t('account.errors.sales') }</p>
      }
      <div className={styles.buttonGroup}>
        <Button
          label={t('account.form.create')}
          onButtonClick={handleSubmit}
          size="small"
          icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
          disabled={(status === 'LOADING')}
        />
        <div className={styles.logIn}>
          <p>
            {t('account.form.already')}
          </p>
          <Link
            to={(window.location.href.search(/[?]/g) !== -1) ? `/?${window.location.href.split('?').pop()}` : '/'}
            className={styles.link}
          >
            {t('account.form.connect')}
          </Link>
        </div>
      </div>
    </form>
  );
}

AccountForm.defaultProps = {
  values: {
    firstname: '',
    lastname: '',
    mail: '',
    password: '',
    newsletter: false,
    sales: false,
  },
  errors: {},
};

AccountForm.proptype = {
  values: Proptypes.shape({
    firstname: Proptypes.string,
    lastname: Proptypes.string,
    mail: Proptypes.string,
    password: Proptypes.string,
    newsletter: Proptypes.bool,
    sales: Proptypes.bool,
  }),
};

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RoleForm from './RoleForm';
import {
  fetchAllRoles, createNewRolesByName, createNewRoleAndAddChild, resetRoleCreation,
} from '../../../../services/store/features/admin/actions';

const mapStateToProps = (state) => ({
  status: state.admin.status,
  roles: state.admin.rawRoles,
  error: state.admin.error,
  creation: state.admin.creation,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllRoles,
    resetRoleCreation,
    createNewRolesByName,
    createNewRoleAndAddChild,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm);

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RoleFormChild from './RoleFormChild';
import { fetchAllRoles, addExistingRolesAsChild, resetAddChildRequest } from '../../../../../services/store/features/admin/actions';

const mapStateToProps = (state) => ({
  status: state.admin.status,
  roles: state.admin.rawRoles,
  creation: state.admin.add,
  error: state.admin.error,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllRoles,
    addExistingRolesAsChild,
    resetAddChildRequest,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleFormChild);

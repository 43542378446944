import axios from 'axios';
import { getStorage, setStorage } from '../../utils/saveLocal';

export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
    // Get cookie for IE (There are two different csrftoken in IE)
    if (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        // Get the last csrftoken for IE
        if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
    }
  }
  return cookieValue;
}

axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken');
axios.defaults.headers.delete['X-CSRFToken'] = getCookie('csrftoken');

const axiosMockClient = axios.create({
  baseURL: '/api',
  timeout: 60000,
});

export const axiosDefaultClient = axios.create({
  baseURL: '/api',
  timeout: 60000,
});

axiosMockClient.interceptors.request.use(
  async (config) => {
    const accessToken = getStorage('auth_token');
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const verifyRefreshToken = async (originalRequest, axiosResponse) => {
  const refreshToken = getStorage('auth_refresh', false);
  /* Get new token by using the refresh token */
  if (refreshToken) {
    try {
      const { data } = await axiosDefaultClient.post('/auth/refresh_token', { refresh_token: refreshToken });
      setStorage('auth_token', data.data.token);
      setStorage('auth_refresh', data.data.refresh_token, false);
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      return axiosMockClient(originalRequest);
    } catch (err) {
      document.location.assign(`/?redirect=${window.location.href}`);
    }
    return Promise.reject(new Error(axiosResponse.data.msg));
  }
  /* no refresh token, user need to log in */
  document.location.assign(`/?redirect=${window.location.href}`);
  return null;
};

const handleResponse = async (axiosResponse) => {
  const originalRequest = axiosResponse.config;
  /* Token invalid */
  if (
    (axiosResponse.status === 401 || (axiosResponse.data.status === 'KO' && axiosResponse.data.code === '401'))
    // eslint-disable-next-line no-underscore-dangle
    && !originalRequest._retry
  ) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    await verifyRefreshToken(originalRequest, axiosResponse);
  }
  return axiosResponse;
};
axiosMockClient.interceptors.response.use(
  handleResponse,
  (response) => handleResponse(response.response),
);

export default axiosMockClient;

import * as types from './types';

const initialState = {
  status: 'IDLE',
  update: 'IDLE',
  validate: 'IDLE',
  deletion: 'IDLE',
  error: null,
  users: [],
  selectedUser: null,
};

export default function usersReducer(state = initialState, actions) {
  const { type, payload } = actions;
  switch (type) {
    case types.FETCH_USERS_REQUEST:
      return { ...state, status: 'LOADING' };
    case types.FETCH_USERS_SUCCEEDED:
      return { ...state, status: 'SUCCESS', users: payload };
    case types.FETCH_USERS_FAILED:
      return { ...state, status: 'FAIL', error: payload };
    case types.FETCH_USER_ROLES_REQUEST:
      return {
        ...state,
        selectedUserRoles: {
          status: 'LOADING',
        },
      };
    case types.FETCH_USER_ROLES_SUCCEEDED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'SUCCEEDED',
        },
        users: state.users.map((user) => ((user.email === payload.email)
          ? { ...user, roles: payload.roles }
          : user)),
      };
    case types.FETCH_USER_ROLES_FAILED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'FAILED',
        },
      };
    case types.SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: payload,
        validate: 'IDLE',
        update: 'IDLE',
        error: null,
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        update: 'LOADING',
      };
    case types.UPDATE_USER_SUCCEEDED:
      return {
        ...state,
        update: 'SUCCESS',
        users: state.users.map((user) => ((user.email === state.selectedUser)
          ? { ...user, ...payload }
          : user)),
      };
    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        update: 'ERROR',
        error: `${payload}`,
      };
    case types.VALIDATE_USER_REQUEST:
      return {
        ...state,
        validate: 'LOADING',
      };
    case types.VALIDATE_USER_SUCCEEDED:
      return {
        ...state,
        validate: 'SUCCESS',
        users: state.users.map((user) => ((user.email === state.selectedUser)
          ? { ...user, validate: 1 }
          : user)),
      };
    case types.VALIDATE_USER_FAILED:
      return {
        ...state,
        validate: 'FAIL',
        error: `${payload}`,
      };
    case types.RESET_UPDATE:
      return {
        ...state,
        update: 'IDLE',
      };
    case types.ADD_USER_ROLES_REQUEST:
      return {
        ...state,
        selectedUserRoles: {
          status: 'LOADING',
        },
      };
    case types.REMOVE_USER_ROLES_REQUEST:
      return {
        ...state,
        selectedUserRoles: {
          status: 'LOADING',
        },
      };
    case types.ADD_USER_ROLES_SUCCEEDED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'SUCCESS',
        },
        users: state.users.map((user) => ((user.email === payload.email)
          ? { ...user, roles: Array.from(new Set([...user.roles, ...payload.roles])) }
          : user)),
      };
    case types.REMOVE_USER_ROLES_SUCCEEDED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'SUCCESS',
        },
        users: state.users.map((user) => ((user.email === payload.email)
          ? { ...user, roles: user.roles.filter((role) => !payload.roles.includes(role)) }
          : user)),
      };
    case types.ADD_USER_ROLES_FAILED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'ERROR',
        },
      };
    case types.REMOVE_USER_ROLES_FAILED:
      return {
        ...state,
        selectedUserRoles: {
          status: 'ERROR',
        },
      };
    case types.DELETE_USER_REQUEST:
      return { ...state, deletion: 'IDLE' };
    case types.DELETE_USER_SUCCEEDED:
      return {
        ...state,
        deletion: 'SUCCESS',
        users: state.users.filter((user) => user.email !== payload),
        selectedUser: null,
      };
    case types.DELETE_USER_FAILED:
      return { ...state, deletion: 'FAIL', error: payload };
    default:
      return state;
  }
}

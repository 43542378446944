import React, { useState } from 'react';

import Navigation from './Navigation';
import UsersList from './Users/UsersList';
import UserDescription from './Users/UserDescription';
import RolesList from './Roles/RolesList';
import RolesDescription from './Roles/RolesDescription';
import RoleForm from './Roles/RoleForm';
import styles from './Admin.module.css';
import { isUserAuthorized } from '../../services/store/features/roles/actions';

export default function Admin({ selectedUser, roles, selectedRole }) {
  const [navigation, setNavigation] = useState('USERS');
  const [isRoleFormOpen, setIsRoleFormOpen] = useState(null);
  const [formType, setFormType] = useState('SINGLE');

  const handleOpen = (role = 'NEW', type = 'SINGLE') => {
    setFormType(type);
    setIsRoleFormOpen(role);
  };

  return (
    <section className={styles.admin}>
      {(navigation === 'USERS') && selectedUser && <UserDescription />}
      {
        (navigation === 'USERS')
        && isUserAuthorized(roles, ['AUTH_USERS_GET'])
        && <UsersList />
      }
      {(navigation === 'ROLES') && (selectedRole && selectedRole !== 'ROOT') && <RolesDescription open={handleOpen} />}
      {(navigation === 'ROLES') && <RolesList open={handleOpen} />}
      {(isRoleFormOpen)
        && (
        <RoleForm
          selected={isRoleFormOpen}
          close={() => setIsRoleFormOpen(null)}
          type={formType}
        />
        )}
      <Navigation roles={roles} navigation={navigation} setNavigation={setNavigation} />
    </section>
  );
}

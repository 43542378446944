import React, { useRef } from 'react';
import { CheckCircle } from '@material-ui/icons';
import styles from '../RoleForm.module.css';
import InputText from '../../../../../components/library/Input/InputText/InputText';
import Button from '../../../../../components/library/Button/Button';
import useOutsideClickAlert from '../../../../../components/library/hook/clickOutside';

export default function RoleFormSingle({
  selected, error, creation, name, setName, isDefault, setIsDefault, close, handleSubmit,
}) {
  const modalRef = useRef();
  useOutsideClickAlert(modalRef, close);
  return (
    <div className={styles.overlay}>
      <section ref={modalRef} className={styles.popup}>
        <header className={styles.header}>
          <h3>
            Create a new role or group
          </h3>
          {
                (selected !== 'NEW')
                && (
                  <p className={styles.create}>
                    {'Create a subrole or group for '}
                    <span>
                      {selected}
                    </span>
                  </p>
                )
             }
        </header>
        {
             (creation === 'ERROR' && error)
             && <p className={styles.error}>{`${error}`}</p>
          }
        {
             (creation === 'SUCCESS')
             && (
               <p className={styles.success}>
                 <CheckCircle className={styles.icon} />
                 Roles has been successfully created
               </p>
             )
          }
        <form className={styles.form}>
          <InputText
            label="Name"
            valueInput={name}
            onTextChange={(e) => setName(e.target.value)}
          />
          <div className={styles.default}>
            <label htmlFor="switchCreate" className={styles.switch}>
              <input
                id="switchCreate"
                type="checkbox"
                checked={isDefault}
                onChange={(e) => setIsDefault(e.target.checked)}
              />
              <span className={`${styles.slider} ${styles.round}`} />
            </label>
            <p>
              Default mode
            </p>
          </div>
        </form>
        <footer className={styles.footer}>
          <Button
            label="Cancel"
            type="custom"
            className={styles.cancel}
            onButtonClick={close}
          />
          <Button
            label={`Create a new ${(selected === 'NEW') ? 'role' : 'subrole'}`}
            onButtonClick={handleSubmit}
          />
        </footer>
      </section>
    </div>
  );
}

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RolesList from './RolesList';
import { fetchAllRoles, setSelectedRoles } from '../../../../services/store/features/admin/actions';

const mapStateToProps = (state) => ({
  status: state.admin.status,
  roles: state.admin.roles,
  selected: state.admin.selectedRole,
  lastSelected: state.admin.lastSelectedRole,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    fetchAllRoles,
    setSelectedRoles,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RolesList);

/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import intlTelInput from 'intl-tel-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './iti.css';
import styles from './InputPhone.module.css';
import stylesInput from '../shared/Input.module.css';
import 'intl-tel-input/build/css/intlTelInput.css';

export default function InputPhone({
  phone, setPhone, required, label, state, msg = null,
}) {
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [phoneIti, setPhoneIti] = useState(null);
  const phoneRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (phoneRef.current) {
      const iti = intlTelInput(phoneRef.current, {
        initialCountry: 'fr',
        nationalMode: true,
        autoPlaceholder: 'aggressive',
        formatOnDisplay: true,
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.js',
      });
      iti.setNumber(phone);
      setPhoneIti(iti);
    }
  }, []);

  useEffect(() => {
    if (phoneIti) {
      phoneIti.setNumber(phone);
    }
  }, [phone]);

  useEffect(() => {
    if (state === 'ERROR') {
      setIsValidPhone(false);
    }
  }, [state]);

  return (
    <div>
      <p
        className={stylesInput.label}
      >
        {(required) && (<span className={styles.required}> * </span>)}
        { label }
      </p>
      <div className={`${stylesInput.input} ${styles.inputPhoneContainer} ${!isValidPhone ? styles.error : ''}`}>
        <input
          ref={phoneRef}
          className={styles.phoneInput}
          type="tel"
          id="phone"
          name="phone"
          onChange={(e) => {
            if (phoneIti !== null) {
              setPhone(phoneIti.getNumber(), e);
              if (phoneIti.isValidNumber()) {
                setIsValidPhone(true);
              } else {
                setIsValidPhone(false);
              }
            }
          }}
          onBlur={(e) => {
            if (phoneIti !== null) {
              if (phoneIti.isValidNumber()) {
                setIsValidPhone(true);
                setPhone(phoneIti.getNumber(), e);
              } else {
                setIsValidPhone(false);
              }
            }
          }}
        />
      </div>
      {
        (!isValidPhone)
        && (
        <p className={`${stylesInput.hint} ${styles.error}`}>
          <FontAwesomeIcon className={styles.iconError} icon={faTimes} />
          {msg || t('account.errors.phoneInvalid')}
        </p>
        )
}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import InputText from '../../../../../../../components/library/Input/InputText/InputText';
import Button from '../../../../../../../components/library/Button/Button';
import { isUserAuthorized } from '../../../../../../../services/store/features/roles/actions';
import { resetPassword, updateUserByEmail } from '../../../../../../../services/network/authentication';

import userStyles from '../../../UserDescription.module.css';
import stylesSecurity from '../Security.module.css';
import styles from './SecurityPassword.module.css';
import CopyPopUp from '../../../../../../../components/CopyPopUp';

export default function SecurityPassword({ roles, email }) {
  const [isForm, setIsForm] = useState(false);
  const [status, setStatus] = useState('IDLE');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [password, setPassword] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsForm(false);
    setStatus('IDLE');
    setError(null);
    setSuccess(null);
    setPassword('');
  }, [email]);

  const handleResetLink = async () => {
    setStatus('LOADING');
    try {
      const { data } = await resetPassword(email);
      if (data.status === 'OK') {
        setStatus('SUCCESS');
        setSuccess('The reset link has been send successfully !');
      } else {
        setStatus('ERROR');
        setError(data.msg);
      }
    } catch (err) {
      setStatus('ERROR');
      setError(`${err}`);
    }
  };

  const handlePasswordGeneration = async () => {
    setIsOpen(true);
    setStatus('LOADING');
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const passwordLength = 8;
    let newPassword = '';
    for (let i = 0; i <= passwordLength; i += 1) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      newPassword += chars.substring(randomNumber, randomNumber + 1);
    }
    try {
      const { data } = await updateUserByEmail(email, { password: newPassword });
      if (data.status === 'OK') {
        setStatus('SUCCESS');
        setPassword(newPassword);
        setSuccess('The password has been changed successfully !');
      } else {
        setStatus('ERROR');
        setError(data.msg);
      }
    } catch (err) {
      setStatus('ERROR');
      setError(`${err}`);
    }
  };

  const handleSubmit = async () => {
    setStatus('LOADING');
    try {
      const { data } = await updateUserByEmail(email, { password });
      if (data.status === 'OK') {
        setStatus('SUCCESS');
        setIsForm(false);
        setSuccess('The password has been changed successfully !');
      } else {
        setStatus('ERROR');
        setError(data.msg);
      }
    } catch (err) {
      setStatus('ERROR');
      setError(`${err}`);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setStatus('IDLE');
  };

  return (
    <>
      {
        isOpen
        && (
          <CopyPopUp
            status={status}
            close={handleClose}
            error={error}
            loading="Password generation ongoing ..."
            success={success}
            value={password}
          />
        )
      }
      <p className={styles.label}>
        Change user&apos;s password
      </p>
      {
        (!isOpen && status === 'SUCCESS')
        && (
          <p className={`bounceIn ${stylesSecurity.success}`}>
            { success }
          </p>
        )
      }
      {
        (!isOpen && status === 'ERROR')
        && (
          <p className={`bounceIn ${stylesSecurity.error}`}>
            {`The request has failed due to ${error}`}
          </p>
        )
      }
      {
        (isForm)
          ? (
            <form className={stylesSecurity.passwordForm}>
              <InputText
                label="New password"
                type="password"
                className={stylesSecurity.passwordInput}
                valueInput={password}
                onTextChange={(e) => setPassword(e.target.value)}
              />
              <Button
                label={(status === 'LOADING') ? 'Submit in progress...' : 'Save'}
                icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
                onButtonClick={handleSubmit}
              />
              <Button
                label="Cancel"
                onButtonClick={() => setIsForm(false)}
              />
            </form>
          )
          : (
            <div className={userStyles.buttonGroup}>
              <Button
                label={(status === 'LOADING') ? 'Sending in progress...' : 'Send a reset link'}
                icon={(status === 'LOADING') && <FontAwesomeIcon icon={faSpinner} spin />}
                onButtonClick={handleResetLink}
              />
              <Button
                label="Generate new password"
                onButtonClick={handlePasswordGeneration}
              />
              {
                (isUserAuthorized(roles, ['AUTH_USERS_EDIT']))
                && (
                  <Button
                    label="Enter a new password"
                    variant="ghost"
                    disabled={(status === 'LOADING')}
                    onButtonClick={() => setIsForm(true)}
                  />
                )
              }
            </div>
          )
      }
    </>
  );
}

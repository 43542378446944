import React from 'react';

import styles from './RolesDescription.module.css';
import Button from '../../../../components/library/Button/Button';

export default function RolesDescription({ role, open, actions }) {
  const handleChange = () => {
    actions.setDefaultRoleStatus(role.name);
  };

  return (
    <section className={styles.main}>
      <header>
        <div>
          <h1 className={styles.title}>
            { role?.name }
          </h1>
          <div className={styles.switchWrapper}>
            <label htmlFor="switch" className={`${styles.switch}`}>
              <input
                id="switch"
                type="checkbox"
                checked={role.default}
                onChange={handleChange}
              />
              <span className={`${styles.slider} ${styles.round}`} />
            </label>
            <p className={styles.default}>Default mode</p>
          </div>
        </div>
        <Button
          label="Delete the group"
          type="primary"
          onButtonClick={() => actions.deleteRoles([role?.name])}
        />
      </header>
      <main className={styles.role}>
        <div className={styles.row}>
          <h2 className={styles.subtitle}>
            {`${role?.name.toLowerCase()}'s subroles`}
          </h2>
          <div className={styles.buttonGroup}>
            <Button
              label="Create multiple subroles"
              onButtonClick={() => open(role?.name, 'MULTIPLE')}
            />
            <Button
              label="Add role as child"
              onButtonClick={() => open(role?.name, 'CHILD')}
            />
          </div>
        </div>
        <div className={styles.subroles}>
          {
            (role?.child && role.child.length > 0)
              ? (
                <ul className={styles.list}>
                  {
                  role?.child.map((r) => (
                    <li key={r?.name}>
                      <button
                        type="button"
                        onClick={() => actions.setSelectedRoles(r?.name)}
                      >
                        { r?.name }
                      </button>
                      <Button
                        label="Remove from the group"
                        type="primary"
                        variant="ghost"
                        className={styles.button}
                        onButtonClick={() => actions.deleteRoles([r?.name], role?.name)}
                      />
                    </li>
                  ))
                }
                </ul>
              )
              : (
                <div className={styles.empty}>
                  <p> No subroles created </p>
                </div>
              )
          }
        </div>
      </main>
    </section>
  );
}

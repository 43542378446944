const handleSearch = (role, query) => {
  if (!query || query.trim() === '') return true;
  if (!role.is_group) {
    const name = `${role.name} ${role.name.split('_').join(' ')}`.toUpperCase();
    return name.includes(query.toUpperCase());
  }
  // eslint-disable-next-line no-param-reassign
  role.child = role.child.filter((child) => handleSearch(child, query));
  return role.child.length;
};

export default handleSearch;
